import React, { useState } from "react";
import { styleProps } from "../../types/common";
import clsx from "clsx";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, Typography, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

type CustomTextInputProps = {
  title: string;
  type: string;
  variant?: any;
  value?: string | number | null;
  label?: string;
  disabled?: boolean;
  customStyle?: styleProps;
  stateKey?: string;
  placeholder?: string | undefined;
  row?: number;
  isOutlined?: boolean;
  isGray?: boolean;
  textLimit?: number;
  handleKeyPress?: (event: any) => void;
  handleChange?: (event: any, statusKey: string) => void;
};

type TextInputProps = {
  type: string;
  variant?: any;
  value?: string | number | null;
  label?: string;
  disabled?: boolean;
  customStyle?: styleProps;
  stateKey?: string;
  row?: number;
  isGray?: boolean;
  handleKeyPress?: (eveny: any) => void;
  handleChange?: (event: any, statusKey: string) => void;
};

const useStyles = makeStyles((theme:any) => ({
  root: {
    "& .MuiInput-root": {
      maxHeight: 45,
      fontSize: 15,
    },
    "& .MuiInput-input": {
      maxHeight: 45,
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "0.5px 14px",
    },
    "& input:disabled": {
      // color: "#CCCCCC",
      color: "#7c7c7c",
    },
  },
  default_root: {
    "& .MuiInput-underline:hover:before": {
      borderBottom: `1px solid ${theme.palette.primary.main}`, // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1px solid ${theme.palette.primary.main}`, // Solid underline on focus
    },
  },
  outlined_root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "red", // (default alpha is 0.38)
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 13,
      paddingTop: 10,
      paddingBottom: 10,
      color: "#000",
    },
  },
  no_disabled_font: {
    "& .MuiInput-input": {
      fontSize: 15,
    },
  },
  text_title: {
    minWidth: "80px",
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
  },
  default_font: {
    color: theme.palette.gray.main,
  },
  gray_font: {
    color: `${theme.palette.gray_2.dark} !important`,
  },
  outlined_font: {
    color: theme.palette.common.black,
    marginBottom: 10,
  },
  spacer: {
    marginTop: 19,
  },
  multiple_text_count_container: {
    textAlign: "end",
    marginTop: -25,
    marginBottom: 25,
    marginRight: 5,
    fontSize: 15,
    color: theme.palette.gray_2.dark,
  },
  icon_size: {
    "& svg": {
      width: 15,
      height: 15,
    },
  },
}));

const PasswordInput: React.FC<TextInputProps> = ({
  type,
  label = "",
  variant,
  value,
  stateKey,
  customStyle,
  handleKeyPress,
  handleChange,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <TextField
      className={clsx(
        classes.root,
        variant === "outlined" && classes.outlined_root,
        variant === "standard" && classes.root
      )}
      value={value}
      variant={variant}
      type={values.showPassword ? "text" : "password"}
      fullWidth
      id="outlined-full-width"
      autoComplete="current-password"
      onKeyPress={(event: any) => handleKeyPress && handleKeyPress(event)}
      onChange={(event) => {
        handleChange && handleChange(event, stateKey ? stateKey : "");
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classes.icon_size}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const CustomTextInput: React.FC<CustomTextInputProps> = ({
  title,
  type,
  variant = "standard",
  label = "",
  value,
  stateKey,
  customStyle,
  disabled,
  placeholder,
  isGray,
  row = 1,
  textLimit,
  isOutlined = false,
  handleKeyPress,
  handleChange,
}) => {
  const classes = useStyles();
  let showElement = null;

  if (type === "password") {
    showElement = (
      <PasswordInput
        type={type}
        variant={variant}
        value={value}
        handleKeyPress={handleKeyPress}
        stateKey={stateKey}
        handleChange={handleChange}
      />
    );
  }

  return (
    <React.Fragment>
      <div className={classes.spacer} style={customStyle}>
        {title && (
          <Typography
            className={clsx(
              classes.text_title,
              variant === "standard" && classes.default_font,
              variant === "outlined" && classes.outlined_font,
              isGray && classes.gray_font
            )}
            align="left"
          >
            {title}
          </Typography>
        )}

        {showElement ? (
          showElement
        ) : (
          <div>
            <TextField
              variant={variant}
              className={clsx(
                classes.root,
                variant === "outlined" && classes.outlined_root,
                variant === "standard" && classes.default_root
              )}
              value={value}
              fullWidth
              label={label}
              type={type}
              disabled={disabled}
              placeholder={placeholder}
              rows={row}
              rowsMax={7}
              multiline={row > 1}
              id={`text-filed-${title}`}
              color="primary"
              // autoComplete="current-name"
              // variant="outlined"
              onKeyPress={(event: any) => handleKeyPress && handleKeyPress(event)}
              onChange={(event) => {
                if (textLimit) {
                  if (JSON.stringify(event.target.value).length <= textLimit) {
                    handleChange && handleChange(event, stateKey ? stateKey : "");
                  }
                } else {
                  handleChange && handleChange(event, stateKey ? stateKey : "");
                }
              }}
            />
            {textLimit && (
              <div className={classes.multiple_text_count_container}>
                {value ? JSON.stringify(value).length : 0}/{textLimit}
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CustomTextInput;
