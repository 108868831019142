import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//Material UI
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core//Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

type VariantIconProps = {
  success: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  warning: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  error: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  info: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
};

const variantIcon: VariantIconProps = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export interface SnackbarMessage {
  message: string;
  key: number;
}

const useStyles1 = makeStyles((theme:any) => ({
  success: {
    backgroundColor: theme.palette.secondary.main,
  },
  error: {
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

// @ts-ignore
function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const key: keyof VariantIconProps = variant;
  const Icon = variantIcon[key];
  return (
    <SnackbarContent
      className={clsx(classes[key], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

// @ts-ignore
export default function CustomizedSnackbars(props) {
  const { open, handleOpen, content = "Network Error", type = 0 } = props;
  // const [snackPack, setSnackPack] = React.useState<SnackbarMessage[]>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  let messageType: string;
  switch (type) {
    case 0:
      messageType = "success";
      break;
    case 1:
      messageType = "error";
      break;
    case 2:
      messageType = "warning";
      break;
    default:
      messageType = "success";
  }

  function handleClose(event?: React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }
    handleOpen({ snackbar: false });
  }


  React.useEffect(() => {
    if (open) {
      if (!isOpen) {
        //first snackbar
        setIsOpen(true);
      } else {
        //second snackbar
        setIsOpen(false);
        handleOpen({ snackbar: false });
        setTimeout(() => {
          handleOpen({ snackbar: true });
        }, 200);
      }
    }
  }, [isOpen]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper onClose={handleClose} variant={messageType} message={content} />
      </Snackbar>
    </div>
  );
}
