import fetchData from "../../utils/fetchData";
import { CreateCompanyAPIProps, GetCompanyListAPIProps, UpdateCompanyAPIProps } from "./type";

export function getCompanyPagination(params: GetCompanyListAPIProps) {
  return fetchData({
    url: "/company",
    method: "GET",
    params: params,
  });
}

export function createCompany(body: CreateCompanyAPIProps) {
  return fetchData({
    url: "/company",
    method: "POST",
    data: body,
  });
}

export function getCompany(companyID: number) {
  return fetchData({
    url: `/company/${companyID}`,
    method: "GET",
  });
}


export function updateCompany(companyID: number, body: UpdateCompanyAPIProps) {
  return fetchData({
    url: `/company/${companyID}`,
    method: "PUT",
    data: body,
  });
}

export function deleteCompany(companyID: number) {
  return fetchData({
    url: `/company/${companyID}`,
    method: "DELETE",
  });
}

export function getAllCompany() {
  return fetchData({
    url: "/company/all",
    method: "GET",
  });
}
