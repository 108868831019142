import { createContext, useContext, useState, useEffect } from "react";
import CustomBackdrop from "../../pages/component/Backdrop";

type BackdropProps = {
  isOpen: boolean;
  setBackdrop?: (event: any) => void;
};

const defaultValue: BackdropProps = {
  isOpen: false,
};

const BackdropContext = createContext(defaultValue);

const getLocalBackdrop = () => {
  try {
    const open = localStorage.getItem("EASYSHOW_BACKDROP");
    if (open === null) {
      return { isOpen: false };
    } else {
      return JSON.parse(open) as BackdropProps;
    }
  } catch (err) {
    console.log("error get backdrop local storage");
    return { isOpen: false };
  }
};

export const useBackdropProps = () => useContext(BackdropContext);
export const BackdropContextProvider = (props: any) => {
  const [backdrop, _setBackdrop] = useState(getLocalBackdrop());

  // const [backdrop, _setBackdrop] = useState(defaultValue);

  const setBackdrop = (newState: BackdropProps): void => {
    _setBackdrop((prevState: BackdropProps) => ({
      ...prevState,
      ...newState,
    }));
  };
  useEffect(() => {
    localStorage.setItem("EASYSHOW_BACKDROP", JSON.stringify(backdrop));
  }, [backdrop.isOpen]);

  
  return (
    <BackdropContext.Provider
      value={{
        // ...backdrop,
        isOpen: backdrop.isOpen,
        setBackdrop: setBackdrop,
      }}
    >
      {props.children}
      <CustomBackdrop isOpen={backdrop.isOpen} />
    </BackdropContext.Provider>
  );
};
