import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory, useParams } from "react-router";
import { useDropzone } from "react-dropzone";
import { routes } from "../config/routes";
import variables from "../config/variables.json";
//context
import { theme, useProps } from "../contexts";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useBackdropProps } from "../contexts/backdrop";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { Grid, Paper, Divider } from "@material-ui/core";
//Component
import CustomHeaderTitle from "./component/CustomHeaderTitle";
import CustomTextInput from "./component/CustomTextInput";
import EmailEditBox from "./component/EmailEditBox";
import CustomButtons from "./component/CustomButtons";
import CustomSearchSelect from "./component/CustomSearchSelect";
import ModelLoader from "./component/ModelLoader";
import { CustomCopyButton } from "./component/CustomCopyToClipboard";
//controller
import {
  CreateProductControllerAPIProps,
  GetProductCategoryControllerProps,
  GetProductControllerProps,
  UpdateProductControllerAPIProps,
} from "../controller/product/type";
import { handleCreateProductAPI, handleGetProductAPI, handleGetProductCategoryAPI, handleUpdateProductAPI } from "../controller/product";
import { handleGetAllAccountControllerAPI } from "../controller/admin";
import { isAccessPermission } from "../utils";

const useStyles = makeStyles((theme) => ({
  container_wrapper: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  left_container: {
    padding: "30px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.05)",
  },
  right_container: {
    // position: "sticky",
    top: "1rem",
    padding: "30px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.05)",
  },
  title_font: {
    fontSize: "13px",
    marginBottom: -5,
    fontWeight: "bold",
    color: theme.palette.gray_1.dark,
  },
  group_container: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  input_container: {
    marginBottom: 20,
  },
  divider: {
    margin: "10px 0px 20px 0px",
  },
  right_or_divider_container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "10px 0px 15px 0px",
  },
  right_divider_text: {
    flex: 0.1,
    textAlign: "center",
    fontSize: 15,
  },
  template_title: {
    fontSize: "15px",
    fontWeight: "bold",
    color: theme.palette.gray_1.dark,
    marginBottom: 10,
  },
  thumbnailContainer: {
    textAlign: "center",
  },
  thumbnail: {
    width: "70%",
    // maxHeight: 135,
    objectFit: "cover",
  },
  dropzone: {
    // display: "none",
    position: "absolute",
    zIndex: 10,
    width: "100%",
    height: "100%",
  },
  private_font: {
    margin: 8, 
    fontSize: 15, 
    color:"red",
    // color: theme.palette.primary.main,
  }
}));

const EditProductPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, _handleChange } = useProps();
  const { setSnackbar } = useSnackbarProps();
  const { setBackdrop } = useBackdropProps();
  const { productID } = useParams<{ productID: string }>();
  const [mainState, _setMainState] = useState({
    productID: parseInt(productID) || 0,
    files: {
      thumbnail: {
        ID: 0,
        name: "",
        path: "",
      },
      model: {
        ID: 0,
        name: "",
        path: "",
      },
    },
    currentThumbnail: { path: "", file: "" },
    name: "",
    content: "",
    initialContent: "",
    email: "",
    status: 3,
    createdAt: "",
    createdBy: { ID: 0, company: { ID: 0, name: "", icon: "" } },
    accountList: [{ value: 0, label: "", company: { ID: 0, name: "", icon: "" } }],
    privateURL: "",
    refreshData: false,
    productCategory: [],
    category: ''
  });

  const accessPermission = !isAccessPermission(user.role, mainState.createdBy ? mainState.createdBy.ID : 1, user.userID);

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleSubmit = () => {
    let thumbnail = "";
    if(mainState.files.thumbnail){
      thumbnail = mainState.files.thumbnail.path
    }
    if(mainState.currentThumbnail.file){
      thumbnail = mainState.currentThumbnail.file
    }

    const params: UpdateProductControllerAPIProps = {
      productID: mainState.productID,
      thumbnail: thumbnail,
      name: mainState.name,
      email: mainState.email,
      content: mainState.content ? mainState.content : mainState.initialContent,
      createdBy: mainState.createdBy ? mainState.createdBy.ID : 0,
      companyID: mainState.createdBy ? mainState.createdBy.company.ID : 0,
      status: mainState.status,
      category: mainState.category,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };
    handleUpdateProductAPI(params).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
      }
    });
  };

  const handleChange = (event: any, prop: any) => {
    setMainState({ [prop]: event.target.value });
  };

  const _handleGetProductAPI = () => {
    if (productID) {
      const params: GetProductControllerProps = {
        productID: mainState.productID,
        setMainState,
        changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      };
      handleGetProductAPI(params);
    }
  };

  // DropZone functions
  const handleOnDrop = (acceptedFiles: any) => {
    if (!acceptedFiles.length) return;

    const tmpData = acceptedFiles.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        remarks: "",
      });
    });
    let obj = { ...mainState.currentThumbnail };
    obj["path"] = tmpData[0].preview;
    obj["file"] = tmpData[0];
    setMainState({ currentThumbnail: obj });
    // setMainState({ currentThumbnail: tmpData[0].preview });
    // setMainState({ files: obj });
  };

  // const handleDropdownList = (event: { value: string | number; label: string }, statusKey: string) => {
  const handleDropdownList = (event: any, statusKey: string) => {
    const value = event.value;
    const company = event.company;
    if (company) {
      setMainState({ [statusKey]: { ID: value, company: company } });
    } else {
      setMainState({ [statusKey]: value });
    }
  };

  const handleDropRejected = (files: any) => {
    if (!files.length) return;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: handleDropRejected,
    multiple: false,
    accept: "image/*",
  });

  const handleCancel = () => {
    history.push(routes.navBar.productList.path);
  };

  const _handleGetProductCategoryAPI = () => {
    const params: GetProductCategoryControllerProps = {
      setMainState,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };
    handleGetProductCategoryAPI(params);
};

  useEffect(() => {
    _handleGetProductAPI();
    _handleGetProductCategoryAPI()
    handleGetAllAccountControllerAPI(setMainState, { setSnackbar });
  }, [mainState.refreshData]);

  let thumbnailImage = mainState.files.thumbnail ? mainState.files.thumbnail.path : "";
  if (mainState.currentThumbnail.path) {
    thumbnailImage = mainState.currentThumbnail.path;
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: 20 }}>
        <CustomHeaderTitle title={"編輯產品"} subTitle={"編輯產品資訊"} />
      </div>
      <Grid spacing={3} container className={classes.container_wrapper}>
        <Grid xs={12} md={8} item>
          <Paper className={classes.left_container}>
            <div className={classes.input_container}>
              <div className={classes.title_font}>產品名稱</div>
              <CustomTextInput
                title={""}
                value={mainState.name}
                type="text"
                stateKey="name"
                variant="outlined"
                placeholder={"輸入產品名稱"}
                disabled={accessPermission}
                // isGray
                handleChange={handleChange}
              />
            </div>
            <div className={classes.input_container}>
              <div className={classes.title_font} style={{ marginBottom: 10 }}>
                創作者
              </div>
              <CustomSearchSelect
                // defaultLabel={"-"}
                defaultLabel={"選擇創作者"}
                initValue={mainState.createdBy ? mainState.createdBy.ID : ""}
                stateKey={"createdBy"}
                handleChange={handleDropdownList}
                listArray={mainState.accountList}
                hasSearch={false}
                disabled={user.role === 2}
              />
            </div>
            <div className={classes.input_container}>
              <div className={classes.title_font}>公司</div>
              <CustomTextInput
                title={""}
                value={mainState.createdBy ? mainState.createdBy.company.name : ""}
                type="text"
                stateKey="companyID"
                variant="outlined"
                placeholder="-无創作者公司-"
                handleChange={handleChange}
                disabled
              />
            </div>
            <div className={classes.input_container}>
              <div className={classes.title_font}>聯絡郵件</div>
              <CustomTextInput
                title={""}
                value={mainState.email}
                type="email"
                stateKey="email"
                variant="outlined"
                placeholder={"輸入聯絡郵件"}
                isGray
                handleChange={handleChange}
                disabled={accessPermission}
              />
            </div>
            {mainState.files.model && (
              <iframe src={`${variables.websiteUrl}/modal-wrapper/${productID}?isProduct=1`} height="800" width="100%" />
              // <ModelLoader link={'https://api-panel.capritech.club/uploads/G76OYgpQ/66200185/scene_out/scene.gltf'} width={300} height={300} />
            )}
            <div style={{ display: "none" }} {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
            </div>
            <div className={classes.input_container}>
              <div className={classes.group_container} style={{ margin: "20px 0" }}>
                <div
                  className={classes.title_font}
                  style={{
                    // width: 60,
                    marginRight: 15,
                  }}
                >
                  顯示內容 :
                </div>
              </div>
              <EmailEditBox
                contentValue={mainState.initialContent}
                setState={(value: any) => setMainState({ content: value })}
                disabled={accessPermission}
              />
            </div>
          </Paper>
        </Grid>
        <Grid xs={12} md={4} item>
          <Paper className={classes.right_container}>
            <CustomButtons
              customStyle={{ marginBottom: 10 }}
              frontIconChildren={<SaveOutlinedIcon style={{ marginLeft: -20 }} />}
              label={"保存"}
              handleClick={handleSubmit}
              variant={"contained"}
              fullWidth
              isWithoutLinear
              disabled={accessPermission}
            />
            <CustomButtons label="取消" handleClick={handleCancel} variant={"text"} fullWidth />

            <React.Fragment>
              <div className={classes.right_or_divider_container}>
                <Divider style={{ flex: 0.45 }} />
                <div className={classes.right_divider_text}>OR</div>
                <Divider style={{ flex: 0.45 }} />
              </div>

              <div className={classes.template_title}>狀態:</div>

              <CustomSearchSelect
                defaultLabel={"-"}
                initValue={mainState.status}
                stateKey={"status"}
                handleChange={handleDropdownList}
                listArray={variables.statusList}
                hasSearch={false}
                disabled={accessPermission}
              />
              <div className={classes.private_font}>
                設定Private的狀態產品將不會在Capritech網站上顯示
              </div>

              {mainState.status === 2 && (
                <CustomCopyButton copyText={mainState.privateURL} buttonText="私用鏈接" />
              )}

            </React.Fragment>
          </Paper>
          <Paper className={classes.right_container} style={{ marginTop: 8 }}>
            <React.Fragment>
            <div className={classes.template_title}>分類:</div>
              <CustomSearchSelect
                defaultLabel={"-"}
                initValue={mainState.category}
                stateKey={"category"}
                handleChange={handleDropdownList}
                listArray={mainState.productCategory}
                hasSearch={false}
                disabled={accessPermission}
              />
            </React.Fragment>
          </Paper>
          <Paper className={classes.right_container} style={{ marginTop: 8 }}>
            <React.Fragment>
              <div className={classes.template_title}>縮略圖:</div>
              {thumbnailImage && (
                <div className={classes.thumbnailContainer}>
                  <img className={classes.thumbnail} src={thumbnailImage} />
                </div>
              )}
              <div style={{ marginTop: 8 }} />
              <CustomButtons
                customStyle={{ padding: "2px 10px", minWidth: 100 }}
                label={`${mainState.files.thumbnail ? "更換":"添加"}縮略圖`}
                handleClick={open}
                variant={"outlined"}
                disabled={accessPermission}
              />
            </React.Fragment>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default EditProductPage;
