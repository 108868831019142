import React from "react";
import Header from "./Header";
import ListItems from "./ListItems";
// material ui
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation } from "react-router";

type NavProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    overflow:"hidden",
    backgroundColor: theme.palette.gray.light,
  },
  bodyWrapper: {
    position: "relative",
    minHeight: "100vh",
    margin: "39px 140px 81px 140px",
    [theme.breakpoints.up(1681)]: {
      maxWidth: 1280,
      margin: "auto",
      marginTop: 39,
      marginBottom: 81,
    },
    [theme.breakpoints.down("md")]: {
      margin: "35px 105px 81px 105px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "35px 18px 61px 18px",
    },
  },
  noBodyWrapper: {
    position: "relative",
    minHeight: "78vh",
    flex: 1,
    padding: "0px",
  },
  leftWrapper: {
    flex: 0.05,
    boxShadow: "0 0 50px 0 rgba(46, 44, 85, 0.12)",
    border: "solid 1px #f4f5f8",
    backgroundColor: theme.palette.common.white,
  },
  rightWrapper: {
    flex: 0.95,
    [theme.breakpoints.down("md")]: {
      flex: 1,
    },
  },
}));

const Layout: React.FC<NavProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const location = useLocation();
  const isSizeDevice = useMediaQuery(theme.breakpoints.down("md"));
  let bodyClass = classes.bodyWrapper;
  // if (
  //   location.pathname.includes("/browse-courses/take-course") ||
  //   location.pathname.includes("/browse-courses/take-lesson") ||
  //   location.pathname.includes("/entry-courses") ||
  //   location.pathname.includes("/achiever-club")
  // ) {
  //   bodyClass = classes.noBodyWrapper;
  // }
  return (
    <React.Fragment>
      <div className={classes.container}>
        {!isSizeDevice && (
          <div className={classes.leftWrapper}>
            <ListItems />
          </div>
        )}
        <div className={classes.rightWrapper}>
          <Header />
          <div className={bodyClass}>{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
