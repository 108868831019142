import { Routes } from "../types/common";
import { getIcon } from "./SidebarIcon";
//Component
import LoginPage from "../pages/LoginPage";
import BoothListPage from "../pages/BoothListPage";
import EditBoothPage from "../pages/EditBoothPage";
import ProductListPage from "../pages/ProductListPage";
import SpecialProductListPage from "../pages/SpecialProductListPage";
import EditProductPage from "../pages/EditProductPage";
import EditSpecialProductPage from "../pages/EditSpecialProductPage";
import CompanyListPage from "../pages/CompanyListPage";
import SettingPage from "../pages/SettingPage";
import Upload3DPage from "../pages/Upload3DPage";
import AccountListPage from "../pages/AccountListPage";
import ModalWrapperPage from "../pages/ModelWrapper";

//ex: "test" Page is under "mainpage"
//link follow by
// mainpage/?:mainpageID/test

//role 1 -  admin , 2 = normal user
//permission - import ,send

const routes: Routes = {
  generals: {
    login: {
      text: "Login",
      path: "/login",
      exact: true,
      component: LoginPage,
      permission:[1,2],
    },
    modal: {
      text: "ModalWrapper",
      path: ["/modal-wrapper", "/modal-wrapper/:boothID"],
      exact: true,
      component: ModalWrapperPage,
      permission:[1,2],
    }
  },
  navBar: {
    boothList: {
      text: "空間設計列表",
      path: "/",
      exact: true,
      children: ["/booth", "/booth/:boothID"],
      component: BoothListPage,
      imageURL: getIcon("home"),
      permission:[1,2],

    },
    editBooth: {
      text: "攤位列表",
      path: ["/booth", "/booth/:boothID"],
      // path: "/stall/:stallID",
      exact: true,
      component: EditBoothPage,
      imageURL: getIcon("home"),
      hidden: true,
            permission:[1,2],

    },
    productList: {
      text: "產品設計列表",
      path: "/product-list",
      exact: true,
      children: ["/product", "/product/:productID"],
      component: ProductListPage,
      imageURL: getIcon("product"),
      permission:[1,2],

    },
    specialProductList: {
      text: "特殊物品設計列表",
      path: "/special-product-list",
      exact: true,
      children: ["/special-product", "/special-product/:productID"],
      component: SpecialProductListPage,
      imageURL: getIcon("product"),
      permission:[1,2],

    },
    editProduct: {
      text: "產品列表",
      path: ["/product", "/product/:productID"],
      // path: "/product/:productID",
      exact: true,
      component: EditProductPage,
      imageURL: getIcon("product"),
      hidden: true,
      permission:[1,2],

    },
    editSpecialProduct: {
      text: "特殊物品列表",
      path: ["/special-product", "/special-product/:productID"],
      // path: "/product/:productID",
      exact: true,
      component: EditSpecialProductPage,
      imageURL: getIcon("product"),
      hidden: true,
      permission:[1,2],

    },
    companyList: {
      text: "公司列表",
      path: "/company-list",
      exact: true,
      component: CompanyListPage,
      imageURL: getIcon("company"),
      permission:[1],

    },
    accountList: {
      text: "管理者列表",
      path: "/account-list",
      exact: true,
      component: AccountListPage,
      imageURL: getIcon("account"),
      permission:[1],

    },
    upload3D: {
      text: "3D頁面",
      path: ["/upload/booth", "/upload/product"],
      exact: true,
      component: Upload3DPage,
      imageURL: getIcon("product"),
      hidden: true,
      permission:[1,2],

    },
    setting: {
      text: "設定",
      path: "/setting",
      exact: true,
      component: SettingPage,
      imageURL: getIcon("setting"),
            permission:[1,2],

    },
  },
};

export { routes };
