import React from "react";
import clsx from "clsx";
import { OnClickEventHandler, OnKeyboardEventHandler } from "../../../types/common";
// Components
// material ui
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../../../../src/logo.svg";
import ListItems from "./ListItems";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  iconButtonWrapper: {},
  iconButton: {},
  logo: {
    height: "75%",
  },
}));

export default function HamburgerDrawer() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const toggleDrawer = (open: boolean) => (event: OnKeyboardEventHandler | OnClickEventHandler) => {
    setOpen(open);
  };

  return (
    <div>
      <IconButton
        className={classes.iconButtonWrapper}
        onClick={toggleDrawer(true)}
        aria-label="User Tools"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        component="span"
      >
        <MenuIcon className={classes.iconButton} />
      </IconButton>
      <SwipeableDrawer anchor="left" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        <ListItems toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>
    </div>
  );
}
