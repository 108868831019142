import React, { useEffect, useRef, useState } from "react";
import { useProps } from "../contexts";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useBackdropProps } from "../contexts/backdrop/index";
import { useHistory } from "react-router";
import useQuery from "../hooks/useQuery";

//Material UI
import { Avatar, Divider, Checkbox, Grid, IconButton, makeStyles } from "@material-ui/core";
import UploadFileIcon from "@material-ui/icons/AddCircle";

//Component
import CustomHeaderTitle from "./component/CustomHeaderTitle";
import CustomTable from "./component/CustomTable";
import RedirectText from "./component/RediractText";
import SearchBar from "./component/SearchBar";
import DeletePopUp from "./component/popup/DeletePopUp";
import CustomButtons from "./component/CustomButtons";

//Assets
import editImg from "../assets/edit_1.png";
import deleteImg from "../assets/Delete.png";
import noImage from "../assets/no-image-found.png";

//controller
import { handleGetAllCompanyAPI } from "../controller/company";
import {
  CreateAccountControllerAPIProps,
  DeleteAccountControllerAPIProps,
  GetAccountListControllerAPIProps,
  UpdateOthersControllerAPIProps,
} from "../controller/admin/type";
import {
  handleCreateAccountControllerAPI,
  handleDeleteAccountControllerAPI,
  handleGetAccountListControllerAPI,
  handleUpdateOthersControllerAPI,
} from "../controller/admin";
import CreateAccountPopUp from "./component/popup/CreateAccountPopUp";

const useStyles = makeStyles((theme) => ({
  title_font: {
    fontSize: 15,
    fontWeight: "bold",
    // margin: "35px 0px 10px 0px",
  },
  icon_btn_ctn: {
    margin: "0px 5px",
    width: 30,
    height: 30,
    backgroundColor: theme.palette.gray_3.light,
  },
  checkbox_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 15,
  },
  search_container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button_container: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sub_title_container: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "20px 0px",
  },
  sub_title_container_text: {
    flex: 0.1,
    [theme.breakpoints.between(325,1280)]: {
      flex: 0.2,
    },
  },
  sub_title_container_line: {
    flex: 0.9,
    [theme.breakpoints.between(325,1280)]: {
      flex: 0.8,
    },
  },
  divider: {
    margin: "10px 0px 5px 0px",
  },
  thumbnailImage: {
    // width: 100,
    // height: 100,
    // objectFit: "cover",
    width: "50px",
    height: "50px",
    marginRight: 10,
  },
}));

const generateBody = (
  classes: any,
  body: Array<any>,
  page: number,
  rowsPerPage: number,
  handleDelete: any,
  handleSelect: any
) => {
  return body.map((e: any, i) => ({
    id: (
      <div key={i} style={{ fontWeight: "bold" }}>
        {(page - 1) * rowsPerPage + i + 1}
      </div>
    ),
    // img: <img className={classes.thumbnailImage} src={e.icon ? e.icon : noImage} alt="image" />,
    name: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar className={classes.thumbnailImage} src={e.icon ? e.icon : noImage} alt="image" />
        <RedirectText id={e.ID} text={e.name} handleClick={() => {}} />
      </div>
    ),
    companyName: <RedirectText id={e.ID} text={e.company.id ? e.company.name : ""} handleClick={() => {}} />,
    // createdAt: e.createdAt,
    button: (
      <div>
        <IconButton className={classes.icon_btn_ctn} onClick={() => handleSelect(e)} aria-label="edit">
          <img style={{ width: 12 }} src={editImg} />
        </IconButton>
        <IconButton
          className={classes.icon_btn_ctn}
          onClick={() => handleDelete(e.ID, e.name)}
          aria-label="edit"
        >
          <img style={{ width: 12 }} src={deleteImg} />
        </IconButton>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  // { id: "img", label: "圖示", align: "left" },
  { id: "name", label: "名稱", align: "left" },
  { id: "companyName", label: "公司名稱", align: "left" },
  //   { id: "createdAt", label: "創建時間 ", align: "left" },
  { id: "button", label: "", align: "left" },
];

const AccountListPage: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = useQuery();
  const { user, _handleChange } = useProps();
  const { setSnackbar } = useSnackbarProps();
  const { setBackdrop } = useBackdropProps();
  const deletePopUp = useRef<any>(null);
  const createAccountPopUp = useRef<any>(null);
  const [mainState, _setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: query.search || "",
    companyID: query.companyID || 0,
    refreshData: false,
    companyList: [],
    selectedList: [],
  });

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleClick = () => {
    createAccountPopUp.current.handleOpen();
    createAccountPopUp.current.setMainState({ isCreate: true, companyList: mainState.companyList });
  };

  const handleSubmit = (value: any, isCreate: boolean) => {
    if (isCreate) {
      const param: CreateAccountControllerAPIProps = {
        ...value,
        changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      };

      handleCreateAccountControllerAPI(param).then((isSuccess) => {
        if (isSuccess) {
          createAccountPopUp.current.handleClose();
          handleRefreshData();
        }
      });
    } else {
      const param: UpdateOthersControllerAPIProps = {
        ...value,
        changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      };
      handleUpdateOthersControllerAPI(param).then((isSuccess) => {
        if (isSuccess) {
          createAccountPopUp.current.handleClose();
          handleRefreshData();
        }
      });
    }
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleDelete = (ID: number, name: string) => {
    deletePopUp.current.handleOpen();
    deletePopUp.current.setMainState({
      ID: ID,
      name: name,
      reqRole: 1,
      role: user.role,
      userID: user.userID,
    });
  };

  const handleSelect = (details: any) => {
    createAccountPopUp.current.handleOpen();
    createAccountPopUp.current.setMainState({
      ...details,
      isCreate: false,
      companyID: details.company.id,
      companyList: mainState.companyList,
    });
  };

  const _handleDeleteAccount = (ID: number) => {
    const params: DeleteAccountControllerAPIProps = {
      ID: ID,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };

    handleDeleteAccountControllerAPI(params).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deletePopUp.current.handleClose();
      }
    });
  };

  const _handleGetAccountList = () => {
    const params: GetAccountListControllerAPIProps = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      companyID: mainState.companyID,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      setMainState: setMainState,
    };
    let obj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      companyID: mainState.companyID,
    };

    setQuery(obj);
    handleGetAccountListControllerAPI(params);
  };
  //API
  const fetchList = async (search: any = undefined) => {
    _handleGetAccountList();
  };

  const handleChangeSearch = async (search: any = undefined) => {
    await fetchList(search);
  };

  useEffect(() => {
    fetchList();
    handleGetAllCompanyAPI(setMainState, setSnackbar);
  }, [mainState.orderBy, mainState.order, mainState.page, mainState.refreshData]);

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limit,
    handleDelete,
    handleSelect
  );

  return (
    <React.Fragment>
      <div style={{ marginBottom: 20 }}>
        <CustomHeaderTitle title="管理者展示列表" subTitle={`瀏覽所有管理者`} />
      </div>
      <Grid spacing={3} container>
        <Grid className={classes.search_container} sm={12} md={6} lg={6} item>
          <SearchBar
            input={mainState.search}
            placeHolder={"搜索"}
            submitSearch={handleChangeSearch}
            handleChange={(event: any) => {
              setMainState({ search: event.target.value });
            }}
          />
        </Grid>
        <Grid className={classes.button_container} xs={12} sm={12} md={6} lg={6} item>
          <CustomButtons
            frontIconChildren={<UploadFileIcon />}
            label={"添加管理者"}
            handleClick={handleClick}
            variant={"contained"}
          />
        </Grid>
      </Grid>
      {/* <div className={classes.title_font}>所有空間設計</div> */}
      <div className={classes.sub_title_container}>
        <div className={classes.sub_title_container_text}>
          <div className={classes.title_font}>所有管理者</div>
        </div>
        <div className={classes.sub_title_container_line}>
          <Divider className={classes.divider} />
        </div>
      </div>
      <CustomTable
        header={header}
        body={body}
        rowsPerPageOptions={[10, 25, 100]}
        count={mainState.total}
        rowsPerPage={mainState.limit}
        page={mainState.page}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
      />
      <DeletePopUp ref={deletePopUp} title="管理者" handleSubmit={_handleDeleteAccount} />
      <CreateAccountPopUp ref={createAccountPopUp} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
};

export default AccountListPage;
