import React, { useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
//Component
import CustomTextInput from "../CustomTextInput";
import CustomButtons from "../CustomButtons";
import CustomSearchSelect from "../CustomSearchSelect";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Divider,
  Avatar,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
//Assets
import ImageProfile from "../../../assets/profile_image.jpeg";

//Those Pages need >> Admin Page
//Use for create and edit admin

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    "& .MuiPaper-root": {
      backgroundColor: "white",
    },
  },
  dialog_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 50px 40px 50px",
    minWidth: "30vw",
    backgroundColor: "white",
  },
  input_container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  input_title: {
    margin: "5px 0px",
    fontSize: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  sub_title_container: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "20px 0px 5px 0px",
  },
  sub_title: {
    // flex: 0.2,
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  submit_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "20px 50px",
  },
  delete_button_container: {
    display: "flex",
    justifyContent: "space-between",
  },
  img_container: {
    position: "relative",
  },
  img_size: {
    width: "84px",
    height: "84px",
    "&:hover": {
      background: "black",
    },
  },
  overlay: {
    position: "absolute",
    display: "flex",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 60,
    opacity: 0,
    transition: ".5s ease",
    backgroundColor: "rgba(0,0,0,0.5)",
    "&:hover": {
      opacity: 1,
    },
    cursor: "pointer",
  },
  camera_icon: {
    color: "white",
  },
  dropzone: {
    // display: "none",
    position: "absolute",
    zIndex: 10,
    width: "100%",
    height: "100%",
  },
  spacer: {
    marginTop: 19,
    textAlign: "left",
  },
  title_font: {
    fontSize: "13px",
    marginBottom: -5,
    fontWeight: 400,
    color: theme.palette.gray_1.dark,
  },
}));

type CreateAccountPopUpProps = {
  handleSubmit: (value: any, isCreate: boolean) => void;
};

const CreateAccountPopUp = React.forwardRef<any, CreateAccountPopUpProps>(({ handleSubmit }, ref) => {
  const classes = useStyles();

  const [mainState, _setMainState] = useState({
    ID: 0,
    icon:"",
    file: "",
    fileName: "",
    name: "",
    username: "",
    password: "",
    confirmPassword: "",
    // company: { id: 0, name: "", icon: "" },
    companyID: 0,
    companyList: [{ value: 0, label: "" }],
    roleType: 0,
    isOpen: false,
    isCreate: true,
  });
  
  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      isOpen: false,
      ID: 0,
      icon:"",
      name: "",
      username: "",
      password: "",
      confirmPassword: "",
      // company: { id: 0, name: "", icon: "" },
      companyID: 0,
      companyList: [{ value: 0, label: "" }],
      file: "",
      fileName: "",
      isCreate: true,
    });
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  const _handleSubmit = () => {
    const returnParam = {
      ID: mainState.ID,
      file: mainState.file ? mainState.file : mainState.icon,
      name: mainState.name,
      username: mainState.username,
      companyID: mainState.companyID,
      roleType: mainState.roleType === 0 ? 2 : mainState.roleType,
      password: mainState.password,
      confirmPassword: mainState.confirmPassword,
    };
    handleSubmit(returnParam, mainState.isCreate);
  };

  const handleTextInput = (event: any, key: any) => {
    setMainState({ [key]: event.target.value });
  };

  const handleDropdownList = (event: { value: string | number; label: string }, statusKey: string) => {
    // const handleDropdownList = (event: any) => {
    const value = event.value;
    setMainState({ [statusKey]: value });
  };

  // DropZone functions
  const handleOnDrop = (acceptedFiles: any) => {
    if (!acceptedFiles.length) return;
    const tmpData = acceptedFiles.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        remarks: "",
      })
    );
    setMainState({ file: tmpData[0], fileName: tmpData[0].preview });
  };

  const handleDropRejected = (files: any) => {
    if (!files.length) return;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: handleDropRejected,
    multiple: false,
    accept: "image/*",
  });

  return (
    <Dialog
      className={classes.dialog}
      open={mainState.isOpen}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        <div className={classes.dialog_container}>
          <div className={classes.input_container}>
            <div className={classes.delete_button_container}>
              <p className={classes.input_title}>{`${mainState.isCreate ? "添加" : "編輯"}管理員`}</p>
            </div>
            <div className={classes.sub_title_container}>
              <Grid container spacing={2}>
                <Grid xs={12} md={12} lg={12} item>
                  <div style={{ display: "flex", marginTop: 27 }}>
                    <Typography style={{ fontSize: 13, marginRight: 22 }}>圖示</Typography>
                    <div className={classes.img_container} onClick={open}>
                      {!mainState.file ? (
                        <Avatar
                          onClick={open}
                          className={classes.img_size}
                          src={mainState.icon ? mainState.icon : ImageProfile}
                        />
                      ) : (
                        <Avatar onClick={open} className={classes.img_size} src={mainState.fileName} />
                      )}
                      <div className={classes.overlay}>
                        <PhotoCameraIcon className={classes.camera_icon} />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} md={12} lg={6} item>
                  <CustomTextInput
                    variant="outlined"
                    value={mainState.name}
                    title="管理員名稱"
                    type="text"
                    stateKey="name"
                    placeholder="輸入管理員名稱"
                    handleChange={handleTextInput}
                  />
                  <CustomTextInput
                    variant="outlined"
                    value={mainState.username}
                    title="管理員帳號"
                    type="text"
                    stateKey="username"
                    placeholder="輸入管理員帳號"
                    handleChange={handleTextInput}
                  />
                </Grid>
                <Grid xs={12} md={12} lg={6} item>
                  <CustomTextInput
                    variant="outlined"
                    value={mainState.password}
                    title="管理員密碼"
                    type="password"
                    stateKey="password"
                    placeholder="輸入管理員密碼"
                    handleChange={handleTextInput}
                  />
                  <CustomTextInput
                    variant="outlined"
                    value={mainState.confirmPassword}
                    title="確認密碼"
                    type="password"
                    stateKey="confirmPassword"
                    placeholder="輸入確認密碼"
                    handleChange={handleTextInput}
                  />
                </Grid>
                <Grid xs={12} md={12} lg={6} item>
                  <div className={classes.title_font} style={{ marginBottom: 10 }}>
                    公司
                  </div>
                  <CustomSearchSelect
                    // defaultLabel={"-"}
                    defaultLabel={"選擇公司"}
                    initValue={mainState.companyID}
                    stateKey={"companyID"}
                    handleChange={handleDropdownList}
                    listArray={mainState.companyList}
                    hasSearch={false}
                  />
                </Grid>
                <div style={{ display: "none" }} {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                </div>
              </Grid>
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.submit_container}>
          <CustomButtons
            label="取消"
            variant="Standard"
            handleClick={handleClose}
            customStyle={{ fontSize: 14, paddingLeft: 40, paddingRight: 40 }}
          />
          <CustomButtons
            label={mainState.isCreate ? "添加" : "編輯"}
            handleClick={_handleSubmit}
            customStyle={{ fontSize: 14, paddingLeft: 40, paddingRight: 40, marginLeft: 10 }}
          />
        </div>
      </div>
    </Dialog>
  );
});

export default CreateAccountPopUp;
