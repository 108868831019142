import React, { useState } from "react";
import { routes } from "../config/routes";
import { useHistory } from "react-router";
import clsx from "clsx";
//context
import { useProps } from "../contexts";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useBackdropProps } from "../contexts/backdrop";
//Component
import CustomTextInput from "./component/CustomTextInput";
import CustomButtons from "./component/CustomButtons";
// Material ui
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, FormControlLabel, Checkbox, Link } from "@material-ui/core";
//img
import banner from "../assets/final-background-1.jpg";
//Controller
import { handleLoginAPI } from "../controller/login";
import { LoginControllerAPIProps } from "../controller/login/type";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundImage: `url(${banner})`,
  },
  section: {
    background: theme.palette.common.white,
    width: "23vw",
    // width: "25vw",
    padding: "29px 57px",
    textAlign: "center",
    zIndex: 1,
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.16)",
    [theme.breakpoints.down("md")]: {
      width: "40vw",
    },
    [theme.breakpoints.down("sm")]: {
      // width: "80vw",
      width: "60vw",
    },
  },
  header_title: {
    fontSize: 28,
    color: "#000",
  },
  inputIcon: {
    fontSize: 30,
    color: theme.palette.common.white,
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
  },
  loginLeftTitle: {
    textAlign: "left",
  },
  login_font: {
    fontSize: 13,
    color: theme.palette.gray_1.light,
    fontFamily: theme.typography.fontFamily,
  },
  create_account_spacer: {
    margin: "19px 0",
  },
  create_account_font: {
    paddingRight: 5,
  },
  remember_me_font: {
    marginLeft: 0,
  },
  phone_small_font: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  password_action_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 19,
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
    },
  },
  font_underline: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  button_container: {
    marginTop: 39,
    marginBottom: 28,
  },
  logo_header: {
    width: "480px",
    [theme.breakpoints.down("lg")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  form_control_root: {
    marginLeft: 0,
    "& .MuiCheckbox-root": {
      marginRight: 13,
      [theme.breakpoints.down("sm")]: {
        marginRight: 5,
      },
      padding: 0,
      "& .MuiIconButton-label": {
        "& .MuiSvgIcon-root": {
          width: "18px",
          height: "18px",
          fill: theme.palette.primary.main,
          // fill: "#f8605f",
        },
      },
    },
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setSnackbar } = useSnackbarProps();
  const { setBackdrop } = useBackdropProps();
  const { user, _handleChange } = useProps();
  const [mainState, _setMainState] = useState({
    username: "",
    password: "",
    rememberAcc: false,
  });

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = (event: any, prop: any) => {
    setMainState({ [prop]: event.target.value });
  };

  const handleLogin = () => {
    const { username, password, rememberAcc } = mainState;
    const params: LoginControllerAPIProps = {
      username,
      password,
      rememberAcc,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop,userContext: _handleChange },
    };
    handleLoginAPI(params).then((isSuccess: boolean) => {
      if (isSuccess) {
        history.push(routes.navBar.boothList.path);
      }
    });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleCheckbox = (event: any) => {
    let value = event.target.checked;
    setMainState({ rememberAcc: value });
  };

  return (
    <div className={classes.wrapper}>
      {/* <div style={{ display: "flex", marginBottom: "46px" }}>
        <img className={classes.logo_header} src={logo_header} alt="" />
      </div> */}
      <Paper square className={classes.section} elevation={1}>
        <Typography className={classes.header_title}>Capritech Panel</Typography>
        <CustomTextInput
          title={"用戶名稱"}
          value={mainState.username}
          type="text"
          stateKey="username"
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          customStyle={{ marginTop: 41 }}
        />
        <CustomTextInput
          title={"密碼"}
          value={mainState.password}
          type="password"
          stateKey="password"
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
        />
        <div className={classes.password_action_container}>
          <FormControlLabel
            className={classes.form_control_root}
            control={<Checkbox name="checkedB" />}
            checked={mainState.rememberAcc}
            label={
              <Typography
                className={clsx(classes.login_font, classes.remember_me_font, classes.phone_small_font)}
              >
                Keep Me logged in
              </Typography>
            }
            onChange={handleCheckbox}
          />
        </div>
        <div className={classes.button_container}>
          <CustomButtons label="Login" handleClick={handleLogin} fullWidth />
        </div>
      </Paper>
    </div>
  );
};
export default LoginPage;
