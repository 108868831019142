import { createContext, useContext, useState, useEffect } from "react";
import CustomSnackbar from "../../pages/component/Snackbar";

type SnackbarProps = {
  snackbar: boolean;
  snackbarType: number;
  snackbarContent: string;
  setSnackbar?: (event: any) => void;
};

const defaultValue: SnackbarProps = {
  snackbar: false,
  snackbarType: 0,
  snackbarContent: "",
};

export const SnackbarContext = createContext(defaultValue);

const getLocalSnackbarItems = () => {
  try {
    const list = localStorage.getItem("EASYSHOW_SNACKBAR");
    if (list === null) {
      return defaultValue;
    } else {
      return JSON.parse(list) as SnackbarProps;
    }
  } catch (err) {
    console.log("error get snackbar local storage");
    return defaultValue;
  }
};

export const useSnackbarProps = () => useContext(SnackbarContext);

export const SnackbarContextProvider = (props: any) => {
  const [snackbar, _setSnackbar] = useState(getLocalSnackbarItems());

  const setSnackbar = (newState: SnackbarProps): void => {
    _setSnackbar((prevState: SnackbarProps) => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    localStorage.setItem("EASYSHOW_SNACKBAR", JSON.stringify(snackbar));
  }, [snackbar.snackbar]);

  return (
    <SnackbarContext.Provider
      value={{
        ...snackbar,
        setSnackbar: setSnackbar,
      }}
    >
      {props.children}
      <CustomSnackbar
        open={snackbar.snackbar}
        type={snackbar.snackbarType}
        content={snackbar.snackbarContent}
        handleOpen={setSnackbar}
      />
    </SnackbarContext.Provider>
  );
};
