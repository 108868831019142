import React, { useEffect, useRef, useState } from "react";
import { routes } from "../config/routes";
import variables from "../config/variables.json";
import Interweave from "interweave";
import { getRoutesWithPath } from "../utils";
import { useHistory } from "react-router";
import useQuery from "../hooks/useQuery";

//context
import { useProps } from "../contexts";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useBackdropProps } from "../contexts/backdrop";

//Material UI
import { Divider, Checkbox, Grid, IconButton, makeStyles } from "@material-ui/core";
import UploadFileIcon from "@material-ui/icons/AddCircle";

//Component
import CustomHeaderTitle from "./component/CustomHeaderTitle";
import CustomTable from "./component/CustomTable";
import RedirectText from "./component/RediractText";
import SearchBar from "./component/SearchBar";
import DeletePopUp from "./component/popup/DeletePopUp";
import CustomButtons from "./component/CustomButtons";

//Assets
import editImg from "../assets/edit_1.png";
import deleteImg from "../assets/Delete.png";
import noImage from "../assets/no-image-found.png";

//Controller
import { handleDeleteProductControllerAPI, handleGetProductListAPI } from "../controller/product";
import {
  DeleteProductControllerAPIProps,
  GetProductListControllerAPIProps,
} from "../controller/product/type";
import {
  formatOverLength,
  getAccountNameByID,
  getHtmlPlainString,
  getStatusName,
} from "../controller/general";
import { getCompanyNameByID, handleGetAllCompanyAPI } from "../controller/company";

const useStyles = makeStyles((theme) => ({
  title_font: {
    fontSize: 15,
    fontWeight: "bold",
    // margin: "35px 0px 10px 0px",
  },
  icon_btn_ctn: {
    margin: "0px 5px",
    width: 30,
    height: 30,
    backgroundColor: theme.palette.gray_3.light,
  },
  checkbox_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 15,
  },
  search_container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button_container: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sub_title_container: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "20px 0px",
  },
  sub_title_container_text: {
    flex: 0.1,
    [theme.breakpoints.between(768,1280)]: {
      flex: 0.2,
    },
    [theme.breakpoints.down("sm")]: {
      flex: 0.3,
    },
  },
  sub_title_container_line: {
    flex: 0.9,
    [theme.breakpoints.between(768,1280)]: {
      flex: 0.8,
    },
    [theme.breakpoints.down("sm")]: {
      flex: 0.7,
    },
  },
  divider: {
    margin: "10px 0px 5px 0px",
  },
  thumbnailImage: {
    width: 100,
    height: 100,
    objectFit: "cover",
  },
}));

const generateBody = (
  classes: any,
  body: Array<any>,
  accountList: Array<any>,
  page: number,
  rowsPerPage: number,
  handleDelete: any
) => {
  return body.map((e: any, i) => ({
    id: <div style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}</div>,
    img: <img className={classes.thumbnailImage} src={e.thumbnail ? e.thumbnail : noImage} alt="image" />,
    name: <RedirectText id={e.ID} text={e.name} handleClick={() => {}} />,
    content:<RedirectText id={e.ID} text={e.content} handleClick={() => {}} />,
    creator: (
      <RedirectText
        id={e.ID}
        text={e.createdBy ? getAccountNameByID(accountList, e.createdBy.ID) : ""}
        handleClick={() => {}}
      />
    ),
    email: <RedirectText id={e.ID} text={e.email} handleClick={() => {}} />,
    status: getStatusName(variables.statusList, e.status),
    createdAt: e.createdAt,
    button: (
      <div>
        <IconButton
          className={classes.icon_btn_ctn}
          href={getRoutesWithPath(routes.navBar.editProduct.path[1], [e.ID])}
          aria-label="edit"
        >
          <img style={{ width: 12 }} src={editImg} />
        </IconButton>
        <IconButton
          className={classes.icon_btn_ctn}
          onClick={() => handleDelete(e.ID, e.name, e.createdBy ? e.createdBy.ID : 0)}
          aria-label="edit"
        >
          <img style={{ width: 12 }} src={deleteImg} />
        </IconButton>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "img", label: "圖示", align: "left" },
  { id: "name", label: "名稱", align: "left" },
  { id: "creator", label: "創作者名稱", align: "left" },
  { id: "companyName", label: "公司名稱", align: "left" },
  { id: "email", label: "聯絡郵件 ", align: "left" },
  { id: "status", label: "狀態 ", align: "left" },
  { id: "createdAt", label: "創建時間 ", align: "left" },
  { id: "button", label: "", align: "left" },
];

const ProductListPage: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = useQuery();
  const { user, _handleChange } = useProps();
  const { setSnackbar } = useSnackbarProps();
  const { setBackdrop } = useBackdropProps();
  const deletePopUp = useRef<any>(null);
  const [mainState, _setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: query.search || "",
    status: query.status || 0,
    refreshData: false,
    companyList: [],
  });

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleClick = () => {
    history.push(routes.navBar.upload3D.path[1]);
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleDelete = (ID: number, name: string, reqRole: number) => {
    deletePopUp.current.handleOpen();
    deletePopUp.current.setMainState({
      ID: ID,
      name: name,
      reqRole: reqRole,
      role: user.role,
      userID: user.userID,
    });
  };

  const _handleDeleteProduct = (ID: number) => {
    const params: DeleteProductControllerAPIProps = {
      productID: ID,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };
    handleDeleteProductControllerAPI(params).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deletePopUp.current.handleClose();
      }
    });
  };

  const _handleGetProductList = () => {
    const params: GetProductListControllerAPIProps = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      status: mainState.status,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      setMainState: setMainState,
    };
    let obj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      status: mainState.status,
    };

    setQuery(obj);
    handleGetProductListAPI(params);
  };
  //API
  const fetchList = async (search: any = undefined) => {
    _handleGetProductList();
  };

  const handleChangeSearch = async (search: any = undefined) => {
    await fetchList(search);
  };

  useEffect(() => {
    fetchList();
    handleGetAllCompanyAPI(setMainState, setSnackbar);
  }, [mainState.orderBy, mainState.order, mainState.page, mainState.refreshData]);

  const body = generateBody(
    classes,
    mainState.list,
    mainState.companyList,
    mainState.page,
    mainState.limit,
    handleDelete
  );

  return (
    <React.Fragment>
      <div style={{ marginBottom: 20 }}>
        <CustomHeaderTitle title="產品展示列表" subTitle={`瀏覽所有產品`} />
      </div>
      <Grid spacing={3} container>
        <Grid className={classes.search_container} sm={12} md={6} lg={6} item>
          <SearchBar
            input={mainState.search}
            placeHolder={"搜索"}
            submitSearch={handleChangeSearch}
            handleChange={(event: any) => {
              setMainState({ search: event.target.value });
            }}
          />
        </Grid>
        <Grid className={classes.button_container} xs={12} sm={12} md={6} lg={6} item>
          <CustomButtons
            frontIconChildren={<UploadFileIcon />}
            label={"添加產品"}
            handleClick={handleClick}
            variant={"contained"}
          />
        </Grid>
      </Grid>
      {/* <div className={classes.title_font}>所有產品</div> */}
      <div className={classes.sub_title_container}>
        <div className={classes.sub_title_container_text}>
          <div className={classes.title_font}>所有產品設計</div>
        </div>
        <div className={classes.sub_title_container_line}>
          <Divider className={classes.divider} />
        </div>
      </div>
      <CustomTable
        header={header}
        body={body}
        rowsPerPageOptions={[10, 25, 100]}
        count={mainState.total}
        rowsPerPage={mainState.limit}
        page={mainState.page}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
      />
      <DeletePopUp ref={deletePopUp} title="產品" handleSubmit={_handleDeleteProduct} />
    </React.Fragment>
  );
};

export default ProductListPage;
