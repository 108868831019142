import React from "react";
import { styleProps } from "../../types/common";
import clsx from "clsx";
//Material UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { PropTypes, Button } from "@material-ui/core";

type InputProps = {
  label: string;
  themeColor?: PropTypes.Color;
  handleClick?: (event: any) => void;
  isWithoutLinear?: boolean;
  isUpload?: boolean;
  isWhite?: boolean;
  fullWidth?: boolean;
  active?: boolean;
  disabled?: boolean;
  deactivate?: boolean;
  variant?: any;
  customStyle?: styleProps;
  frontIconChildren?: any;
  backIconChildren?: any;
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    textTransform: "none",
    width: "100%",
    // backgroundImage: linear-gradient(132deg, #ff6f47 4%, #ef4871 128%);
    background: "linear-gradient(108deg, #ff6f47 17%, #f04643 110%)",
    boxShadow: "0 0 10px 0 var(--grapefruit-37)",
    fontSize: 15,
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    padding: "8px 34px",
    textTransform: "none",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      padding: "8px 20px",
    },
  },
  default_root_color: {
    color: theme.palette.common.white,
    background: "linear-gradient(108deg, #b7a4ed 17%, #8E6EE7 110%)",
  },
  default_root_color_without_linear: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    background: "none",
  },
  outline_root_color: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  text_root_color: {
    color: "#97989b",
  },
  default_upload_color: {
    background: "none",
    width: "100%",
    borderColor: "#c6ccd6",
    color: theme.palette.gray_1.main,
    backgroundColor: theme.palette.gray.light,
  },
  fullWidth: {
    width: "100%",
  },
  themeBackground: {
    color: theme.palette.primary.main,
  },
  deactivate: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#f44336",
    },
  },
  white_color: {
    backgroundColor: "white",
  },
}));

export default function CustomButtons(props: InputProps) {
  const {
    label,
    handleClick,
    fullWidth,
    active,
    disabled,
    deactivate,
    themeColor,
    customStyle,
    frontIconChildren,
    backIconChildren,
    isUpload = false,
    isWhite = false,
    isWithoutLinear,
    variant = "contained",
  } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <Button
        variant={variant}
        style={customStyle}
        color={themeColor}
        disabled={disabled}
        className={clsx(
          classes.root,
          variant === "text" && classes.text_root_color,
          variant === "outlined" && classes.outline_root_color,
          variant === "contained" && classes.default_root_color,
          isWithoutLinear && classes.default_root_color_without_linear,
          isUpload && classes.default_upload_color,
          isWhite && classes.white_color,
          fullWidth && classes.fullWidth,
          active && classes.themeBackground,
          deactivate && classes.deactivate
        )}
        onClick={handleClick}
        startIcon={frontIconChildren}
        endIcon={backIconChildren}
      >
        {label}
      </Button>
    </React.Fragment>
  );
}
