import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Tooltip } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  linkText: {
    // cursor: "pointer",
    // textDecoration: "underline",
    fontSize: 14,
    "&:hover": {},
  },
  default_color_font: {
    color: theme.palette.gray_2.main,
  },
  black_font: {
    color: "#000",
  },
  details_fix_height: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2, //2line
    "-webkit-box-orient": "vertical",
  },
}));

type RedirectTextProps = {
  id: number;
  text: string;
  tooltip?: string;
  textColor?: "default" | "black";
  hideText?: boolean;
  handleClick: (id: number) => void;
};

const RedirectText: React.FC<RedirectTextProps> = (props) => {
  const { id, text, tooltip, handleClick, textColor = "default", hideText = false } = props;
  const classes = useStyles();

  const _handleClick = () => {
    handleClick(id);
  };

  return (
    <React.Fragment>
      {tooltip ? (
        <Tooltip title={tooltip} placement="top-start">
          <Typography
            className={clsx(
              classes.linkText,
              textColor === "default" && classes.default_color_font,
              textColor === "black" && classes.black_font,
              hideText && classes.details_fix_height
            )}
            onClick={_handleClick}
          >
            {text}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          className={clsx(
            classes.linkText,
            textColor === "default" && classes.default_color_font,
            textColor === "black" && classes.black_font,
            hideText && classes.details_fix_height
          )}
          onClick={_handleClick}
        >
          {text}
        </Typography>
      )}
    </React.Fragment>
  );
};

export default RedirectText;
