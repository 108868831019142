import fetchData from "../../utils/fetchData";
import { CreateBoothAPIProps, GetBoothListAPIProps, UpdateBoothAPIProps } from "./type";

export function getBoothList(params: GetBoothListAPIProps) {
  return fetchData({
    url: "/booth",
    method: "GET",
    params: params,
  });
}

export function createBooth(body: CreateBoothAPIProps) {
  return fetchData({
    url: "/booth/create-3d",
    method: "POST",
    data: body,
  });
}

export function update3DBooth(boothID: number, body: string) {
  return fetchData({
    url: `/booth/save-3d/${boothID}`,
    method: "PUT",
    data: body,
  });
}

export function getBoothByID(boothID: number) {
  return fetchData({
    url: `/booth/${boothID}`,
    method: "GET",
  });
}

export function updateBooth(boothID: number, body: UpdateBoothAPIProps) {
  return fetchData({
    url: `/booth/${boothID}`,
    method: "PUT",
    data: body,
  });
}

export function deleteBooth(boothID: number) {
  return fetchData({
    url: `/booth/${boothID}`,
    method: "DELETE",
  });
}

export function getBoothCategory() {
  return fetchData({
    url: `/booth-category`,
    method: "GET",
  });
}