export type ContextProps = {
  snackbarContext: any;
  backdropContext?: any;
  userContext?: any;
};

export const apiMiddleware = async (
  changeContext: ContextProps,
  callbackFunctions: any,
  data: any = undefined,
  data2: any = undefined,
  data3: any = undefined
) => {
  // const { setBackdrop }: any = useBackdropProps();
  const { snackbarContext, backdropContext } = changeContext;
  const snackbarParams: { [key: string]: any } = {
    snackbar: true,
    snackbarType: 1,
    snackbarContent: "",
  };

  // changeContext({ openBackdrop: true });
  backdropContext && backdropContext({ isOpen: true });
  try {
    const result = await callbackFunctions(data, data2, data3);
    if (!result) {
      snackbarParams.snackbarContent = result.data.message;
      snackbarContext(snackbarParams);
    }

    //error 400,401,403
    if (result && (result.success === false || result.status === (400 || 401 || 403))) {
      snackbarParams.snackbarContent = result.data.message;
      snackbarContext(snackbarParams);
    }
    backdropContext && backdropContext({ isOpen: false });

    // changeContext({ openBackdrop: false });
    return result;
  } catch (error) {
    snackbarParams.snackbarContent = error;
    snackbarContext({ ...snackbarParams });
  }
};
