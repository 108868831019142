import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// routes
import { routes } from "./config/routes";
import Layout from "./pages/component/navigationBar/Layout";

const Fallback = () => <div>Loading...</div>;

const wrapper = (Component: React.ComponentType) => () => {
  return (
    <React.Fragment>
      {/*<WithTracker {...props}>*/}
      <Component />
      {/*</WithTracker>*/}
    </React.Fragment>
  );
};

function Router() {
  return (
    <BrowserRouter basename="/">
      <Suspense fallback={<Fallback />}>
        <Switch>
          {Object.keys(routes.generals).map((key) => (
            <Route
              key={routes.generals[key].path}
              exact={routes.generals[key].exact}
              path={routes.generals[key].path}
              render={wrapper(routes.generals[key].component)}
            />
          ))}

          <Layout>
            <Switch>
              {Object.keys(routes.navBar).map((key) => (
                <Route
                  key={routes.navBar[key].path}
                  exact={routes.navBar[key].exact}
                  path={routes.navBar[key].path}
                  render={wrapper(routes.navBar[key].component)}
                />
              ))}
            </Switch>
          </Layout>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
