import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { routes } from "../../../config/routes";
import { OnClickEventHandler, OnKeyboardEventHandler } from "../../../types/common";
// material ui
import { makeStyles, withStyles } from "@material-ui/styles";
import { List, ListItem, ListItemText, Button } from "@material-ui/core";
//logo
import Logo from "../../../assets/logo_temp.png";
import { useProps } from "../../../contexts";

type ListProps = {
  toggleDrawer?: (state: boolean) => (event: OnKeyboardEventHandler | OnClickEventHandler) => void;
};

type LinkItemProps = {
  classes: any;
  text: string;
  to: string;
  children: Array<string> | undefined;
  exact: boolean;
  imageURL: any;
  toggleDrawer?: (state: boolean) => (event: OnKeyboardEventHandler | OnClickEventHandler) => void;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: 0,
    backgroundColor: "white",
    flex: 1,
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  logo_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "90px",
  },
  full_icon_width: {
    width: "100%",
  },
  logo_icon: {
    width: "100%",
    marginTop: 15,
    marginBottom: 30,
    // width: "70px",
    // height: "50px",
    padding: 10,
  },
}));

type LinkItemTextProps = {
  listItemClass: any;
  imageURL: any;
  text: string;
  isActive?: boolean;
};

const LinkItemTextIcon: React.FC<LinkItemTextProps> = ({ listItemClass, isActive, imageURL, text }) => {
  const classes = useStyles();

  return (
    <div className={listItemClass.list_item_container}>
      <div style={isActive ? { width: 30, color: "white" } : { width: "30px", color: "lightgray" }}>
        {/* <imageUrl className={classes.full_icon_width} /> */}
        {imageURL}
        {/* <img className={classes.full_icon_width} src={imageURL} alt="iqi-icon-logo" /> */}
      </div>
      <ListItemText
        classes={{ primary: listItemClass.typography }}
        className={listItemClass.noWrap}
        primary={text}
      />
    </div>
  );
};
const LinkItem = withStyles((theme) => ({
  noWrap: {
    color: "white",
    textTransform: "none",
    width: "74px",
    textAlign: "center",
    margin: "0",
  },
  active: {
    boxSizing: "border-box",
    "& .MuiTypography-root": {
      color: "#fff",
    },
  },
  linkText: {
    justifyContent: "center",
    transition: "all 0.1s",
    color: theme.palette.primary.main,
  },
  typography: {
    color: theme.palette.gray.dark,
    fontSize: 11,
    lineHeight: 1,
    paddingTop: 2,
    paddingBottom: "2px",
  },
  button_background: {
    minWidth: "74px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.18);",
    background: "linear-gradient(108deg, #b7a4ed 17%, #8E6EE7 110%)",
    // background: "linear-gradient(108deg, #ff6f47 17%, #ef4871 110%)",
    borderRadius: "10px",
    padding: 0,
  },
  list_item_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px 10px",
  },
}))(({ classes, text, to, children, exact, imageURL, toggleDrawer }: LinkItemProps) => {
  const renderLink = React.forwardRef((itemProps, ref) => <NavLink to={to} {...itemProps} />);

  const isMatchActive = (match: any, location: any) => {
    const locationName = location.pathname.split("/")[1];
    let isMatch: boolean = false;

    if (locationName === to.split("/")[1]) {
      isMatch = true;
    }

    if (children) {
      children.map((e: string) => {
        const mainPath = e.split("/")[1];
        if (locationName === mainPath) isMatch = true;
      });
    }

    return isMatch;
  };
  return (
    <ListItem
      className={clsx(classes.linkText)}
      // @ts-ignore
      component={renderLink}
      to={to}
      isActive={isMatchActive}
      activeClassName={classes.active}
      exact={exact}
      onClick={toggleDrawer && toggleDrawer(false)}
    >
      {isMatchActive(false, window.location) ? (
        <Button className={classes.button_background}>
          <LinkItemTextIcon listItemClass={classes} isActive imageURL={imageURL.active} text={text} />
        </Button>
      ) : (
        <LinkItemTextIcon listItemClass={classes} imageURL={imageURL.index} text={text} />
      )}
    </ListItem>
  );
});

const ListItems = (props: ListProps) => {
  const { toggleDrawer } = props;
  const classes = useStyles();
  const { user } = useProps();

  return (
    <React.Fragment>
      <List className={classes.root}>
        <a className={classes.flex} href={routes.navBar.boothList.path}>
          <div className={classes.logo_container}>
            <img className={classes.logo_icon} src={Logo} alt="logo" />
          </div>
        </a>
        {Object.keys(routes.navBar).map((key) => {
          return (
            !routes.navBar[key].hidden &&
            routes.navBar[key].permission.includes(user.role) && (
              <LinkItem
                key={routes.navBar[key].text}
                exact
                text={routes.navBar[key].text}
                to={routes.navBar[key].path}
                children={routes.navBar[key].children}
                imageURL={routes.navBar[key].imageURL}
                toggleDrawer={toggleDrawer}
              />
            )
          );
        })}
      </List>
    </React.Fragment>
  );
};

export default ListItems;
