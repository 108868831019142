import { FunctionComponent, ReactNode, useState } from "react";
import Select, { StylesConfig, defaultTheme } from "react-select";
import AsyncSelect from "react-select/async";

import { styleProps } from "../../types/common";
//Material UI
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

export interface StateOption {
  readonly value: number;
  readonly label: string;
}

type SelectProps = {
  customStyle?: styleProps;
  stateKey?: string;
  handleChange: (
    event: { value: string; label: string;},
    stateKey: string
  ) => void;
  initValue: string | number | null;
  listArray?: any;
  disabled?: boolean;
  defaultLabel?: string;
  hasSearch?: boolean;
};

const { colors } = defaultTheme;

const useStyles = makeStyles((theme:any) => ({
  button: {
    boxShadow: "none !important",
    height: "38px",
    width:"100%",
    color: "#787878",
    fontSize: "15px",
    justifyContent: "space-between",
    textTransform:"none",
    // border:"1px solid #dfe2e6",
    borderColor:"rgba(0,0,0,0.23)",
    // border:`1px solid ${theme.palette.primary.main}`,
  },
}));

const CustomSearchSelect: React.FC<SelectProps> = ({
  customStyle,
  handleChange,
  stateKey,
  initValue,
  listArray,
  disabled,
  defaultLabel,
  hasSearch = true,
}) => {
  const selectStyles: StylesConfig<StateOption, false> = {
    control: (provided: any) => ({
      ...provided,
      display: hasSearch ? "flex" : "none",
      // minWidth: 240,
      margin: 8,
      cursor: "pointer",
    }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    option: (style: any) => ({ ...style, cursor: "pointer !important" }),
    indicatorSeparator: (styles:any) => ({ display: "none !important" }),
  };

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  let list:any = [];

  if (listArray) {
    list = listArray;
  }
  let obj = list.find((element:any) => {
    if (element.value === initValue) {
      return element;
    }
  });

  let defaultText = defaultLabel ? defaultLabel : "-";

  const [state, setState] = useState("");

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = (value: any) => {
//   const onSelectChange = (value: OnChangeValue<StateOption, false>) => {
    toggleOpen();
    handleChange && handleChange(value, stateKey ? stateKey : "");
  };

  const filterState = (inputValue: string) => {
    const inputLowerCase = inputValue.toLowerCase();
    return list
      .filter((i:any) => i.label.toLowerCase().includes(inputLowerCase))
      .sort((a: any, b: any) => a.label.indexOf(inputLowerCase) - b.label.indexOf(inputLowerCase));
  };

  const handleInputChange = (value: string) => {
//   const handleInputChange = (value: OnChangeValue<StateOption, false>) => {
    setState(value);
  };

  const loadOptions = (inputValue: string, callback: (options: StateOption[]) => void) => {
    callback(filterState(inputValue));
  };

  return (
    <div>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <Button
            disabled={disabled}
            style={customStyle}
            variant="outlined"
            color={"primary"}
            className={classes.button}
            onClick={toggleOpen}
            endIcon={<ChevronDown />}
          >
            {obj ? `${obj.label}` : defaultText}
          </Button>
        }
      >
        <AsyncSelect
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          noOptionsMessage={() => null}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={onSelectChange}
          options={list}
          styles={selectStyles}
          tabSelectsValue={false}
          value={obj}
          cacheOptions //cache loaded data
          loadOptions={loadOptions}
          defaultOptions //show before searching
          onInputChange={handleInputChange}
        />
      </Dropdown>
    </div>
  );
};

// styled components
const Menu = (props: JSX.IntrinsicElements["div"]) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        width:"100%",
        position: "absolute",
        zIndex: 2,
        cursor: "pointer",
      }}
      {...props}
    />
  );
};

const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
      cursor: "pointer",
    }}
    {...props}
  />
);

interface DropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}

const Dropdown: FunctionComponent<DropdownProps> = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Svg = (p: JSX.IntrinsicElements["svg"]) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);

const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path d="M 8 10 l 4 4 l 4 -4 z" 
    // fill="red" 
    fill="#8251dd"
    fillRule="evenodd" />
  </Svg>
);

export default CustomSearchSelect;
