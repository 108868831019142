import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import $ from 'jquery';
import { GUI } from 'dat.gui';
import { Circle } from "rc-progress";
// Treejs Component
import {
    Scene,
    Color,
    SpotLight,
    PointLight,
    HemisphereLight,
    Mesh,
    PMREMGenerator,
    PerspectiveCamera,
    WebGLRenderer,
    Box3,
    Raycaster,
    Vector2,
    Vector3,
    EquirectangularReflectionMapping,
    MeshPhongMaterial,
    Fog,
    MeshPhysicalMaterial,
    DoubleSide,
    FrontSide,
    BackSide,
    PCFShadowMap,
    ACESFilmicToneMapping,
} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
// Material UI
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {makeStyles} from "@material-ui/core/styles";
import {validate3DFileFormat} from "../../utils";
import { createGlobalStyle } from "styled-components";
import CustomButtons from "./CustomButtons";
import {handleUpdate3DDataControllerAPI} from "../../controller/booth";
import {useParams} from "react-router";
import {useSnackbarProps} from "../../contexts/snackbar/SnackbarContext";
import {useBackdropProps} from "../../contexts/backdrop";
import {UpdateBooth3DControllerAPIProps} from "../../controller/booth/type";
import { UpdateProduct3DControllerAPIProps } from "../../controller/product/type";
import { handleUpdate3DProductDataControllerAPI } from "../../controller/product";

type LoaderProps = {
    link: string;
    width: number,
    height: number,
    idName?: string
    defaultData?: any,
    isProduct?: boolean
}

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        fontSize: 14,
    },
    innerAlert: {
        position: 'absolute',
    }
}));

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GlobalStyles = createGlobalStyle`
     alertContainer: {
         fontSize: 14,
     },
     innerAlert: {
         position: 'absolute',
     }
`;

const ModelLoader: React.FC<LoaderProps> = (props) => {
    const classes = useStyles();
    const { boothID } = useParams<{ boothID: string }>();
    const { setSnackbar } = useSnackbarProps();
    const { setBackdrop } = useBackdropProps();
    const dataFetchedRef = useRef(false);
    const { width, height, link, idName, defaultData } = props;
    const isProduct = props.isProduct ? props.isProduct : false;
    const [modelData, setModelData] = useState<any>(defaultData);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(false);
    const params = {
        color: 0xffffff,
        transmission: 1,
        opacity: 1,
        metalness: 1,
        roughness: 0.1,
        ior: 1.5,
        thickness: 0.01,
        specularIntensity: 1,
        specularColor: 0xffffff,
        envMapIntensity: 1,
        lightIntensity: 1,
        exposure: 1
    };

    let modelJSON = {};
    let controls: any, camera: any, renderer: any, scene: any, hdrEquirect: any;
    let targetName = idName ? idName : "3d-loader";

    let activeMesh: any;
    let gui: any;

    const createControls = (meshParams: any) =>
    {
        if(gui) gui.destroy();
        gui = new GUI();

        gui.add(meshParams, 'name').listen().enabled = false;
        gui.add(meshParams, 'materialName').listen().enabled = false;

        gui.add(meshParams,'shadow').onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                if(value==true)
                {
                    activeMesh.castShadow = true;
                    activeMesh.receiveShadow = true;
                }
                else
                {
                    activeMesh.castShadow = false;
                    activeMesh.receiveShadow = false;
                }

            }
        }).listen();

        gui.add(meshParams,'shine').onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                if(value==true)
                {
                    activeMesh.material.envMap = hdrEquirect;
                }
                else
                {
                    delete activeMesh.material.envMap;
                }

            }
        }).listen();
        gui.add(meshParams, 'reflectivity', 0.01, 1).listen().onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                activeMesh.material.reflectivity = value;
            }
        }).listen();


        gui.add(meshParams,'doubleSide').onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                if(value==true)
                {
                    activeMesh.material.side = DoubleSide;
                }
                else
                {
                    activeMesh.material.side = 0;
                }

            }
        }).listen();


        gui.add(meshParams, 'metalness', 0.01, 1).listen().onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                activeMesh.material.metalness = value;
            }
        }).listen();

        gui.add(meshParams, 'roughness', 0.01, 1).listen().onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                activeMesh.material.roughness = value;
            }
        }).listen();

        gui.add(meshParams, 'opacity', 0.01, 1).onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                activeMesh.material.transparent = true;
                activeMesh.material.opacity = value;
            }
        }).listen();

        gui.addColor(meshParams, 'color').onChange(function(value: any) {
            if(activeMesh ?? false)
            {
                activeMesh.material.color.set(value);
            }
        });

        
    }

    const init = () => {

        if (modelData) {
            modelJSON = modelData
        }
        // if(localStorage.getItem(props.link))
        // {
        //     /* LOAD JSON */
        //     modelJSON = JSON.parse(localStorage.getItem(props.link) as string);
        // }
        setInterval(function()
        {
            /* SAVE JSON */
            // localStorage.setItem(props.link,JSON.stringify(modelJSON));
            setModelData(JSON.stringify(modelJSON))
            console.log("Saved");
        },1000);


        const container = document.createElement("div");
        // @ts-ignore
        const target: any = document.getElementById(targetName);

        let first = target.firstElementChild;
        while (first) {
            first.remove();
            first = target.firstElementChild;
        }

        target.appendChild(container);

        // renderer = new WebGLRenderer({ alpha: true, antialias: true });
        // // renderer.setClearColor(0x000000, 0);
        // //renderer.setClearColor(0x000, 1.0);
        // renderer.setSize(width, height);

        // renderer.shadowMap.enabled = true;
        // //renderer.shadowMap.type = PCFSoftShadowMap;
        // renderer.shadowMap.type = PCFShadowMap;
        // renderer.outputEncoding = sRGBEncoding;
        // renderer.toneMapping = ACESFilmicToneMapping;


        renderer = new WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        // renderer.setSize(window.innerWidth, window.innerHeight);
        if(isProduct){
            renderer.setSize(window.innerWidth, window.innerHeight);
        }else{
            renderer.setSize(width, height);
        }
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = PCFShadowMap;
        renderer.toneMapping = ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        target.appendChild(renderer.domElement);


        const meshParams = {
            opacity: 1.0,
            name: "Mesh Name",
            materialName: "",
            color: "#000000",
            metalness: 0,
            roughness: 0,
            shine:false,
            reflectivity:0,
            //specularIntensity:0,
            shadow:false,
            doubleSide:false,

        };
        let activeMeshParams: any = {};


        //const gui = new GUI();
        createControls(meshParams);

        // container.appendChild(renderer.domElement);

        camera = new PerspectiveCamera(45, width / height, 1, 2000);
        camera.position.set(-10, 0, 60);
        // camera.lookAt( 0, 0, 0 );

        controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 0.5, 0);

        controls.enableDamping = true;
        controls.dampingFactor = 0.05;

        // controls = new OrbitControls( camera, renderer.domElement );
        // controls.listenToKeyEvents( window ); // optional
        // //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)
        // controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        // controls.dampingFactor = 0.05;
        // controls.screenSpacePanning = false;
        // controls.minDistance = 100;
        // controls.maxDistance = 500;
        // controls.maxPolarAngle = Math.PI / 2;

        // controls = new OrbitControls(camera, renderer.domElement);
        // controls.target.set(0, 0.5, 0);
        // controls.update();
        // controls.enablePan = false;
        // controls.enableDamping = true;

        const pmremGenerator = new PMREMGenerator(renderer);
        scene = new Scene();
        // scene.background = new Color(0xa0a0a0);
        scene.fog = new Fog(0xa0a0a0, 10, 500);
        // scene.background = new Color(0xbfe3dd);
        // scene.environment = pmremGenerator.fromScene(new RoomEnvironment(), 0.04).texture;


        // const hemiLight = new HemisphereLight(0xffffff, 0x444444);
        // hemiLight.position.set(0, 20, 0);
        // scene.add(hemiLight);

        // const dirLight = new DirectionalLight(0xffffff);
        // dirLight.position.set(-3, 10, -10);
        // dirLight.castShadow = true;
        // dirLight.shadow.camera.top = 2;
        // dirLight.shadow.camera.bottom = -2;
        // dirLight.shadow.camera.left = -2;
        // dirLight.shadow.camera.right = 2;
        // dirLight.shadow.camera.near = 0.1;
        // dirLight.shadow.camera.far = 40;
        // scene.add(dirLight);


        // gemFrontMaterial = new MeshPhysicalMaterial( {
        //   map: null,
        //   // color: 0x0000ff,
        //   metalness: 0,
        //   roughness: 0,
        //   // opacity: 0.25,
        //   side: FrontSide,
        //   transparent: true,
        //   envMapIntensity: 10,
        //   premultipliedAlpha: true
        // } );

        // geometry = new CircleGeometry( 40, 64 );
        // groundMirror = new Reflector( geometry, {
        //   clipBias: 0.003,
        //   textureWidth: window.innerWidth * window.devicePixelRatio,
        //   textureHeight: window.innerHeight * window.devicePixelRatio,
        //   color: 0x777777
        // } );
        // groundMirror.position.y = 0.5;
        // groundMirror.rotateX( - Math.PI / 2 );
        // scene.add( groundMirror );

        // Instantiate a loader

        const materials = {};
        const loader = new GLTFLoader();
        loader.load(
            link,
            function (gltf) {
                const model = gltf.scene;

                const bbox = new Box3().setFromObject(model);
                const cent = bbox.getCenter(new Vector3());
                const size = bbox.getSize(new Vector3());


                const newMaterial = new MeshPhongMaterial();
                // Grass Transparent


                model.traverse((child: any) => {

                    if(child.type.includes("Light") || child.type.includes("light"))
                    {
                        child.visible = false;
                    }
                    if (child.isMesh) {

                        child.castShadow = true;
                        child.receiveShadow = true;
                        



                        let special = false;

                        if (child.material)
                        {
                            if (child.material.name.includes('Aluminum') || child.material.name.includes('Aluminium') || child.material.name.includes('aluminium'))
                            {
                                //console.log("isalumii");
                                // let texture = new CanvasTexture(generateTexture())
                                // texture.magFilter = NearestFilter;
                                // texture.wrapS = RepeatWrapping
                                // texture.wrapT = RepeatWrapping
                                // texture.repeat.set( 1, 3.5 );

                                child.material = new MeshPhysicalMaterial( {
                                    metalness: params.metalness,
                                    roughness: params.roughness,
                                    ior: params.ior,
                                    // normalMap: texture,
                                    // alphaMap: texture,
                                    envMap: hdrEquirect,
                                    envMapIntensity: params.envMapIntensity,
                                    transmission: params.transmission, // use material.transmission for glass materials
                                    specularIntensity: params.specularIntensity,
                                    // @ts-ignore
                                    specularColor: params.specularColor,
                                    opacity: params.opacity,
                                    side: 0,
                                    // flatShading: true,
                                    // transparent: true
                                } );

                                // child.material = new MeshPhongMaterial({
                                //   color: '0xff0000',
                                //   specular: '0x444444',
                                //   shininess: 20,
                                //   metalness: params.metalness,
                                // });

                                special = true
                            }

                            // Transparent Material
                            // if (child.material.name === 'Translucent_Glass_Gray') {
                            if (child.material.name.includes('Translucent_') || child.material.name.includes('translucent')) {
                                child.material.transparent = true
                                child.material.opacity = 0.0
                                //console.log(child.material);
                                special = true
                            }

                            if (child.material.name.includes('clearglass')) {
                                child.material.transparent = true
                                child.material.opacity = 0.2
                                special = true
                            }

                            /*if (child.material.name.includes('poster')) {
                              //child.material.transparent = true
                              //child.material.opacity = 0.2
                              special = true
                            }*/

                            //|| child.material.name.includes('logo')
                            if (child.material.name.includes('screen')) {
                                child.material.transparent = true
                                child.material.opacity = 0.2
                                special = true
                            }

                            /*if (child.material.name.includes('logo')) {

                              special = true
                            }*/
                            if (child.material.name.includes("Color")) {
                                //child.castShadow = true;
                                //child.receiveShadow = true;
                                special = true
                            }


                            if (!special)
                            {
                                if ( child instanceof Mesh ) {


                                    // ------------------------------------------------------------------ //
                                    // Shadow Material
                                    const originalMaterial = child.material;
                                    // @ts-ignore
                                    if(materials[originalMaterial.name])
                                    {
                                        // @ts-ignore
                                        child.material = materials[originalMaterial.name];
                                    }
                                    else
                                    {
                                        //const newMaterial = new MeshStandardMaterial();
                                        const newMaterial = new MeshPhysicalMaterial();
                                        //const newMaterial = new MeshLambertMaterial();
                                        //const newMaterial = new MeshPhongMaterial();
                                        newMaterial.name = originalMaterial.name;
                                        // @ts-ignore
                                        materials[originalMaterial.name] = newMaterial;
                                        // @ts-ignore
                                        newMaterial.specular = new Color(0x050505);
                                        // @ts-ignore
                                        newMaterial.shininess = 100;
                                        // these are missing and required
                                        child.geometry.computeVertexNormals();

                                        // use the texture from the original material
                                        //newMaterial.flatShading = true;
                                        if(child.material && child.material.map) newMaterial.side = FrontSide;
                                        newMaterial.color = originalMaterial.color;
                                        newMaterial.map = originalMaterial.map;
                                        child.material = newMaterial;

                                    }





                                    // ------------------------------------------------------------------ //
                                }
                            }
                        }

                        /* For custom configuration of textures, apply the configurations */
                        // @ts-ignore
                        if(modelJSON[child.material.name])
                        {
                            // @ts-ignore
                            let m = modelJSON[child.material.name];

                            child.material.color = new Color(m.color);
                            if(m.doubleSide==true)
                            {
                                child.material.side = DoubleSide;
                            }
                            else
                            {
                                child.material.side = 0;
                            }

                            child.material.opacity = m.opacity;
                            if(m.opacity>0.0)
                            {
                                child.material.transparent = true;
                            }

                            if(m.metalness)
                            {
                                child.material.metalness = m.metalness;
                            }
                            if(m.roughness)
                            {
                                child.material.roughness = m.roughness;
                            }

                            if(m.shadow)
                            {
                                child.receiveShadow = true;
                                child.castShadow = true;
                            }
                            else
                            {
                                child.receiveShadow = false;
                                child.castShadow = false;
                            }

                            if(m.shine)
                            {
                                child.material.envMap = hdrEquirect;
                                child.material.reflectivity = m.reflectivity;
                            }


                        }
                    }

                })

                //Rescale the object to normalized space
                const maxAxis = Math.max(size.x, size.y, size.z);
                model.scale.multiplyScalar(60.0 / maxAxis);
                bbox.setFromObject(model);
                bbox.getCenter(cent);
                bbox.getSize(size);
                //Reposition to 0,halfY,0
                model.position.copy(cent).multiplyScalar(-1);
                model.position.y -= size.y * 0.1;
                model.receiveShadow = true;
                model.castShadow = true;
                scene.add(model);
                //model.position.set(0,-2,0);
                spotLight.target = model;
                animate();
            },
            // called while loading is progressing
            function (xhr) {
                const progress = (xhr.loaded / xhr.total) * 100;
                setProgress(progress);
                // console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // called when loading has errors
            function (error) {
                console.error("An error happened", error);
                setError(true);
            }
        );
        //animate();
        //setProgress(100);

        // ---------------------------------------------------------------------------- //

        // const light = new DirectionalLight( 0xffffff, 1 );
        // light.position.set( 10, 10, 10 ); //default; light shining from top
        // light.castShadow = true; // default false
        // scene.add( light );
        // //
        // //Create a helper for the shadow camera (optional)
        // const helper = new CameraHelper( light.shadow.camera );
        // scene.add( helper );

        // const blight = new DirectionalLight( 0xffffff, 1 );
        // blight.position.set( 50, 10, 10 ); //default; light shining from top
        // blight.castShadow = true; // default false
        // scene.add( blight );
        //
        // //Create a helper for the shadow camera (optional)
        // const bhelper = new CameraHelper( blight.shadow.camera );
        // scene.add( bhelper );

        //
        // const flight = new DirectionalLight( 0xffffff, 1 );
        // flight.position.set( 1, 1, 5 ); //default; light shining from top
        // flight.castShadow = true; // default false
        // scene.add( flight );

        // light.shadow.mapSize.width = 512; // default
        // light.shadow.mapSize.height = 512; // default
        // light.shadow.camera.near = 0.5; // default
        // light.shadow.camera.far = 500; // default



        // ---------------------------------------------------------------------------- //

        scene.receiveShadow = true;
        scene.castShadow = true;

        // const ambientLight = new HemisphereLight(
        //   'white', // bright sky color
        //   'darkslategrey', // dim ground color
        //   1, // intensity
        // );
        // scene.add( ambientLight );

        const ambient = new HemisphereLight( 0xffffff, 0x444444, 1 );
        scene.add( ambient );

        // var spotLight = new SpotLight( 0xff0000, 10 );
        /*var spotLight = new SpotLight( 0xffffff, 10 );
        spotLight.position.set( 30, 50, 25 );
        spotLight.angle = Math.PI / 6;
        spotLight.penumbra = 1;
        spotLight.decay = 2;

        spotLight.distance = 100;
        spotLight.map = { none: null };
        spotLight.castShadow = true;
        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;
        spotLight.shadow.camera.near = 10;
        spotLight.shadow.camera.far = 200;*/


        var spotLight = new SpotLight( 0xffffff, 100 );
        spotLight.position.set( 10, 10, 15 );
        spotLight.angle = Math.PI / 6;
        spotLight.penumbra = 2;
        spotLight.decay = 0;
        spotLight.intensity = 0.7;




        const pointLight = new PointLight(0xffffff, 1, 50);
        pointLight.castShadow = true;
        pointLight.position.set(0, 0, 0);

        // Add the light to the scene
        scene.add(pointLight);

        // Create a helper for the light
        //const helper = new PointLightHelper(pointLight, 1);
        //scene.add(helper);

        //spotLight.distance = 10;
        //spotLight.map = { none: null };
        spotLight.castShadow = true;
        spotLight.shadow.bias = 0.0001;
        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;
        spotLight.shadow.camera.near = 10;
        spotLight.shadow.camera.far = 200;

        //spotLight.shadow.focus = 1;
        //scene.add(spotLight);
        //const spotlightHelper = new SpotLightHelper(spotLight);
//scene.add(spotlightHelper);


        //spotLight.shadow = new SpotLightShadow(new PerspectiveCamera(20, 1, 1, 250));


        //const geometry = new PlaneGeometry( 1000, 1000 );
        //const material = new MeshLambertMaterial( { color: 0x808080 } );

        // const mesh = new Mesh( geometry, material );
        // mesh.position.set( 0, - 1, 0 );
        // mesh.rotation.x = - Math.PI / 2;
        // mesh.receiveShadow = true;
        // scene.add( mesh );
        let INTERSECTED: any;

        renderer.domElement.addEventListener( 'mousemove', (event: any) => {
          const pointer = new Vector2();
          pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
				  pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
          const raycaster_m = new Raycaster();
          raycaster_m.setFromCamera(pointer, camera);
          const _intersects = raycaster_m.intersectObjects(scene.children, true);
          if ( _intersects.length > 0 ) {
            // console.log('INTERSECTED', INTERSECTED)
            if ( INTERSECTED != _intersects[ 0 ].object ) {
              if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
              INTERSECTED = _intersects[ 0 ].object;
              INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
              INTERSECTED.material.emissive.setHex( 0xff0000 );
            }
          } else {
  
            if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
  
            INTERSECTED = null;
  
          }

        });

        renderer.domElement.addEventListener('click', (event: any) => {
            // Get the mouse position relative to the canvas
            const mouse = new Vector2();
            // mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
            // mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;

            // // Create a raycaster and intersect it with the meshes
            // const raycaster = new Raycaster();
            // raycaster.setFromCamera(mouse, camera);
            // const intersects = raycaster.intersectObjects(scene.children, true);

            // Check if any meshes were clicked
            if (INTERSECTED) {
              console.log('CLICK', INTERSECTED)

                /* Create a json data for the selected mesh */
                // @ts-ignore
                if(!modelJSON[INTERSECTED.material.name])
                {
                    // @ts-ignore
                    modelJSON[INTERSECTED.material.name] = JSON.parse(JSON.stringify(meshParams));
                    //activeMeshParams = modelJSON[INTERSECTED.geometry.id];
                }
                // @ts-ignore
                activeMeshParams = modelJSON[INTERSECTED.material.name];
                createControls(activeMeshParams);


                activeMesh = INTERSECTED;
                // @ts-ignore
                window["mesh"] = activeMesh; //remove

                activeMeshParams.name = INTERSECTED.name;
                // @ts-ignore
                activeMeshParams.materialName = INTERSECTED.material.name;
                // @ts-ignore
                activeMeshParams.opacity = INTERSECTED.material.opacity;
                // @ts-ignore
                activeMeshParams.doubleSide = (INTERSECTED.material.side==DoubleSide)?true:false;

                // @ts-ignore
                activeMeshParams.shine = (INTERSECTED.material.envMap)?true:false;
                // @ts-ignore
                activeMeshParams.reflectivity = (INTERSECTED.material.reflectivity)?INTERSECTED.material.reflectivity:0;

                // @ts-ignore
                activeMeshParams.metalness = (INTERSECTED.material.metalness)?INTERSECTED.material.metalness:0;
                activeMeshParams.shadow = INTERSECTED.castShadow;
                // @ts-ignore
                activeMeshParams.roughness = (INTERSECTED.material.roughness)?INTERSECTED.material.roughness:0;
                // @ts-ignore
                activeMeshParams.color = "#" + INTERSECTED.material.color.getHexString(); 
                // @ts-ignore
                window["json"] = modelJSON; //remove

            }
        });





        renderer.render(scene, camera);
    };

    function valueToHex(c: any) {

      var hex = c.toString(16);
    
      return hex
    
    }

    function rgbToHex(r: any, g: any, b: any) {
      return(valueToHex(r) + valueToHex(g) + valueToHex(b));
    }

    function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
    }

    const handleUpdate = () => {
        if(isProduct){
            const params: UpdateProduct3DControllerAPIProps = {
                product3DData: modelData,
                productID: boothID,
                changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
            }
            handleUpdate3DProductDataControllerAPI(params).then((isSuccess) => {
                if (isSuccess) {

                }
            });
        }else{
            const params: UpdateBooth3DControllerAPIProps = {
                booth3DData: modelData,
                boothID: boothID,
                changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
            }
            handleUpdate3DDataControllerAPI(params).then((isSuccess) => {
                if (isSuccess) {

                }
            });
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;


        if (validate3DFileFormat(link)) {

            hdrEquirect = new RGBELoader()
                .setPath( '/textures/' )
                .load( 'royal_esplanade_1k.hdr', function () {
                    hdrEquirect.mapping = EquirectangularReflectionMapping;

                    init();
                } );


        }
    }, [link, width, height]);

    useEffect(() => () => gui.destroy(), [])

    return (
        <React.Fragment>
            <GlobalStyles />
            {validate3DFileFormat(link) ? (
                <div style={{ width: isProduct ? '' : width, height: isProduct ? '' : height, position: "relative" }}>
                    {error && (
                        <Alert severity="warning" className="innerAlert alertContainer">
                            {/*{languagePackage.FILE_LOAD_FAIL}*/}
                        </Alert>
                    )}
                    {/* {progress !== 100 && <LinearProgress variant="determinate" value={progress} />} */}
                    {progress !== 100 && (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <Circle style={{ width: 50 }} strokeWidth={4} strokeColor={"#8364e2"} percent={progress} />
                        </div>
                    )}
                    <div id={targetName} />
                </div>
            ) : (
                <Alert severity="warning" className="alertContainer">
                    {/*{languagePackage.FILE_NO_FOUND}*/}
                </Alert>
            )}

            <CustomButtons
                customStyle={{ position: 'fixed', bottom: 0, zIndex: 9999}}
                label="保存"
                handleClick={handleUpdate}
                variant={"contained"}
                fullWidth
            />
        </React.Fragment>
    );
};

export default ModelLoader;
