import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { createContext, useContext } from "react";
import { IApp } from "../types/app";
import variables from "../config/variables.json";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    gray: Palette["primary"];
    gray_1: Palette["primary"];
    gray_2: Palette["primary"];
    gray_3: Palette["primary"];
  }
  interface PaletteOptions {
    gray: PaletteOptions["primary"];
    gray_1: PaletteOptions["primary"];
    gray_2: PaletteOptions["primary"];
    gray_3: PaletteOptions["primary"];
  }
}
declare module "@material-ui/core/styles/createTypography" {
  interface TypographyOptions {
    largeFont: TypographyOptions["subtitle1"];
  }
  interface Typography {
    largeFont: Typography["subtitle1"];
  }
}

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1040,
      xl: 1440,
    },
  },
  palette: {
    gray: {
      main: "#b1b4b8",
      dark: "#9b9da4",
      light: "#f4f5f8",
    },
    gray_1: {
      main: "#6a6a6a",
      light: "#787878",
      dark: "#4b4b4b",
    },
    gray_2: {
      main: "#848484",
      light: "#dfe2e6",
      dark: "#9ea3a8",
    },
    gray_3: {
      main: "#000000",
      light: "#f5f8fa",
    },
    primary: {
      main: "#8251dd", //6425D9
      light: "#D8CCEE",
      contrastText: "#000000",
    },
    secondary: {
      main: "rgb(20,167,72)",
    },
    error: {
      main: "#19857b",
    },
    success: {
      main: "rgb(248,96,95,0.37)",
    },
    info: {
      main: "#3265B5",
    },
    background: {
      default: "#242021",
      paper: "#F7F7F7",
    },
    text: {
      disabled: "#8080804a",
    },
  },
  typography: {
    largeFont: {},
    subtitle1: {},
    // fontFamily: `Poppins`,
    fontSize: 20,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 900,
  },
});

theme = responsiveFontSizes(theme);

let defaultStatus: IApp = {
  isLogin: false,
  user: {
    username: "Tester Wong",
    email:"",
    role:0,
    userID: 0,
  },
};

try {
  const json = window.localStorage.getItem(variables.local_storage_version);
  if (json) {
    defaultStatus = JSON.parse(json) as IApp;
  }
} catch (error) {
  console.log("error get local storage");
}

const utilsContext = createContext(defaultStatus);
const useProps = () => useContext(utilsContext);

export { theme, defaultStatus, utilsContext, useProps };
