import { makeStyles } from "@material-ui/core";
import React, { useRef } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import variables from "../../config/variables.json";
import { useProps } from "../../contexts";
import { Editor } from "@tinymce/tinymce-react";
import { handleUploadImage } from "../../controller/general";
import { useSnackbarProps } from "../../contexts/snackbar/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  edit_wrapper: {
    // maxHeight: "300px !important",
    // minHeight: "300px !important",
    // // maxWidth: "43vw !important",
    // borderStyle: "solid",
    // borderWidth: 1,
    // borderColor: theme.palette.gray.main,
    maxWidth: "50vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
    },
  },
  edit_container: {
    maxHeight: 500,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxHeight: "100%",
    },
  },
}));

type EmailEditBoxProps = {
  contentValue: any;
  setState: (value: any) => void;
  disabled?: boolean;
};
const EmailEditBox: React.FC<EmailEditBoxProps> = ({ contentValue, setState, disabled = false }) => {
  const { _handleChange } = useProps();
  const { setSnackbar } = useSnackbarProps();
  const classes = useStyles();
  const editorRef = useRef(null);
  const useDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const uploadImage = async (blobInfo: any, success: any, failure: any) => {
    let dataInfo = blobInfo.blob();
    const uri = blobInfo.uri();

    if (uri) {
      // if (dataInfo instanceof File) {
      const index = uri.lastIndexOf("/");
      const name = uri.slice(index + 1);
      dataInfo = new File([dataInfo], name, { type: dataInfo.type });
    }
    handleUploadImage(dataInfo, {snackbarContext: setSnackbar}).then(({ isSuccess, data }:any) => {
      if (isSuccess) {
        success(data.link);
      }
    });
  };

  const onChange = (content: string) => {
    setState(content);
  };

  // @ts-ignore
  return (
    <Editor
      apiKey={variables.tinymce_key}
      // @ts-ignore
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={contentValue}
      onEditorChange={onChange}
      disabled={disabled}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
          "imagetools",
        ],
        imagetools_cors_hosts: ["picsum.photos"],
        toolbar:
          "undo redo | fontselect | fontsizeselect |" +
          "bold italic forecolor backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist | " +
          "removeformat fullscreen | image link",
        toolbar_sticky: true,
        fontsize_formats: "9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt 48pt 64pt 72pt 96pt 144pt 288pt",
        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}",
        toolbar_mode: "sliding",
        contextmenu: "link image imagetools table",
        skin: useDarkMode ? "oxide-dark" : "oxide",
        automatic_uploads: true,
        file_picker_types: "image media",
        // images_upload_handler: uploadImage,
      }}
    />
  );
};

export default EmailEditBox;
