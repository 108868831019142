//icon
import email from "../assets/sidebar/email.png";
import email_active from "../assets/sidebar/email_active.png";
import sent from "../assets/sidebar/allsend.png";
import sent_active from "../assets/sidebar/allsend_active.png";
import customer_list from "../assets/sidebar/customerlist.png";
import customer_list_active from "../assets/sidebar/customerlist_active.png";
import email_tmp from "../assets/sidebar/emailtemp.png";
import email_tmp_active from "../assets/sidebar/emailtemp_active.png";
import account from "../assets/sidebar/account.png";
import account_active from "../assets/sidebar/account_active.png";
import setting from "../assets/sidebar/setting.png";
import setting_active from "../assets/sidebar/setting_active.png";
//material-ui
import Home from "@material-ui/icons/HomeOutlined";
import Product from "@material-ui/icons/ViewListOutlined";
import Company from "@material-ui/icons/BusinessOutlined";
import Setting from "@material-ui/icons/AccountCircleOutlined";
import Account from '@material-ui/icons/GroupOutlined';

const IconList = [
  // { name: "email", active: email_active, index: email },
  // { name: "sent", active: sent_active, index: sent },
  // { name: "emailTmp", active: email_tmp_active, index: email_tmp },
  // { name: "customerList", active: customer_list_active, index: customer_list },
  // { name: "admin", active: account_active, index: account },
  // { name: "setting", active: setting_active, index: setting },
  { name: "home", active: <Home />, index: <Home />, icon: <Home /> },
  { name: "product", active: <Product />, index: <Product />, icon: <Product /> },
  { name: "company", active: <Company />, index: <Company />, icon: <Company /> },
  { name: "account", active: <Account />, index: <Account />, icon: <Account /> },
  { name: "setting", active: <Setting />, index: <Setting />, icon: <Setting /> },
];

const getIcon = (icon: string) => {
  const result = IconList.find((key) => key.name === icon);
  return result;
};

export { getIcon };
