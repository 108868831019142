import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import {
  createProduct,
  deleteProduct,
  getProductByID,
  getProductCategory,
  getProductList,
  getSpecialProductList,
  update3DProduct,
  updateProduct,
} from "../../services/product";
import { GetProductListAPIProps } from "../../services/product/type";
import { apiMiddleware } from "../../utils/middleware";
import { isEmpty, isEmptyImg, isEmptySelect, validEmail } from "../../utils/validation";
import {
  CreateProductControllerAPIProps,
  DeleteProductControllerAPIProps,
  GetProductCategoryControllerProps,
  GetProductControllerProps,
  GetProductListControllerAPIProps,
  UpdateProduct3DControllerAPIProps,
  UpdateProductControllerAPIProps,
} from "./type";

let contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetProductListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  status,
  changeContext,
  setMainState,
}: GetProductListControllerAPIProps) => {
  const params: GetProductListAPIProps = {
    page,
    limit,
    order,
    orderBy,
    search,
    status,
  };
  const { data, success } = await apiMiddleware(changeContext, getProductList, params);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
};

export const handleGetSpecialProductListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  status,
  changeContext,
  setMainState,
}: GetProductListControllerAPIProps) => {
  const params: GetProductListAPIProps = {
    page,
    limit,
    order,
    orderBy,
    search,
    status,
  };
  const { data, success } = await apiMiddleware(changeContext, getSpecialProductList, params);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
};

export const handleCreateProductAPI = async ({ files, changeContext }: CreateProductControllerAPIProps) => {
  if (files && !(files.type.match("zip.*") || files.type.match("rar.*"))) {
    contextParams.snackbar = true;
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = "檔案格式錯誤";
    changeContext.snackbarContext(contextParams);
    return { isSuccess: false };
  }

  const formData = new FormData();
  formData.append("file", files);

  const { data, success } = await apiMiddleware(changeContext, createProduct, formData);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "建立成功!";
    changeContext.snackbarContext(contextParams);
    return { isSuccess: success, ID: data.productID };
  } else {
    return { isSuccess: success };
  }
};

export const handleGetProductAPI = async ({
  productID,
  setMainState,
  changeContext,
}: GetProductControllerProps) => {
  const { data, success } = await apiMiddleware(changeContext, getProductByID, productID);

  if (success) {
    const { ID, content, createdBy,createdAt, email, files, name, status, privateURL, category, objectData } = data;

    setMainState({
      productID: ID,
      initialContent: content,
      createdAt: createdAt,
      createdBy: createdBy,
      email: email,
      files: files,
      name: name,
      status: status,
      category: category,
      privateURL: privateURL,
      objectData: objectData
    });
  }
};

export const handleUpdateProductAPI = async ({
  productID,
  thumbnail,
  name,
  email,
  content,
  createdBy,
  companyID,
  status,
  category,
  changeContext,
}: UpdateProductControllerAPIProps) => {
  if (status < 3) {
    let thumbnailValue = thumbnail;
    if (typeof thumbnail === "object") {
      thumbnailValue = thumbnail.name;
    }
    const verifyArr = [
      isEmpty(name, "產品名稱"),
      // isEmptySelect(companyID, "公司"),
      isEmptySelect(createdBy, "創作者"),
      validEmail(email),
      isEmptyImg(thumbnailValue, "縮略圖"),
      isEmpty(content, "顯示內容"),
    ];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

    if (!isValidate) return;
  }
  const formData = new FormData();
  // formData.append("file", files.model);
  formData.append("file", thumbnail);
  formData.append("name", name);
  formData.append("email", email);
  formData.append("content", content);
  formData.append("createdBy", JSON.stringify(createdBy));
  // formData.append("companyID", JSON.stringify(companyID));
  formData.append("status", JSON.stringify(status));
  formData.append("category", JSON.stringify(category));

  const { data, success } = await apiMiddleware(changeContext, updateProduct, productID, formData);
  if (success) {
    //setLocalStorage
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
  }

  return success;
};

export const handleDeleteProductControllerAPI = async ({
  productID,
  changeContext,
}: DeleteProductControllerAPIProps) => {
  const { data, success } = await apiMiddleware(changeContext, deleteProduct, productID);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "刪除成功!";
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleGetProductCategoryAPI = async ({
  setMainState,
  changeContext,
}: GetProductCategoryControllerProps) => {
  const { data, success } = await apiMiddleware(changeContext, getProductCategory);

  if (success) {
    setMainState({
      productCategory: data,
    });
  }
};

export const handleUpdate3DProductDataControllerAPI = async ({
  product3DData,
  productID,
  changeContext,
}: UpdateProduct3DControllerAPIProps) => {
  const body = {saveData: product3DData};
  const { success } = await apiMiddleware(changeContext, update3DProduct, productID, body);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
  }

  return success;
}