import { apiMiddleware, ContextProps } from "../../utils/middleware";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { uploadImage } from "../../services/general";

let contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleUploadImage = async (file: any, changeContext: ContextProps
  ) => {
    const formData = new FormData();
    if (file) formData.append("file", file);

    const { data, success } = await apiMiddleware(changeContext, uploadImage, formData);
    if (success) {
      //setLocalStorage
      return { isSuccess: true, data: data };
    } else {
      // contextParams.snackbarContent = data.message;
      contextParams.snackbarContent = "Something Went Error";

      changeContext.snackbarContext(contextParams);
      return { isSuccess: false, data: null };
    }
  };

  export const htmlConvertToDraft = (html: any) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    } else {
      return EditorState.createEmpty();
    }
  };

  export const draftConvertToHtml = (editorState: any) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  export const getStatusName = (list: { label: string; value: number }[], statusID: number) => {
    let status = "";

    list.map((item, value) => {
        if (item.value === statusID) status = item.label.toString();
    });

    return status;
};

  export const getHtmlPlainString = (htmlString: string) => {
    let result = htmlString.replace(/<[^>]+>/g, '');
    return result.replace(/&.*;/g,'');
  }

  export const formatOverLength = (text: string, limit: number) => {
    if (text.length > limit) {
      return `${text.substr(0, limit)}...`;
    } else return text;

  }

  export const getAccountNameByID = (accountList: { label: string; value: number }[], ID: number) => {
  let name = "";

  if (accountList.length === 0) return name;

  accountList.map((item: any) => {
    if (item.value === ID) {
      name = item.label.toString();
    }
  });
  return name;
};
