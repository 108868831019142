import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import {createBooth, deleteBooth, getBoothByID, getBoothCategory, getBoothList, update3DBooth, updateBooth} from "../../services/booth";
import { GetBoothListAPIProps } from "../../services/booth/type";
import { apiMiddleware } from "../../utils/middleware";
import { isEmpty, isEmptyImg, isEmptySelect, validEmail } from "../../utils/validation";
import {
  CreateBoothControllerAPIProps,
  DeleteBoothControllerAPIProps,
  GetBoothCategoryControllerProps,
  GetBoothControllerProps,
  GetBoothListControllerAPIProps, UpdateBooth3DControllerAPIProps,
  UpdateBoothControllerAPIProps,
} from "./type";

let contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetBoothListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  status,
  changeContext,
  setMainState,
}: GetBoothListControllerAPIProps) => {
  const params: GetBoothListAPIProps = {
    page,
    limit,
    order,
    orderBy,
    search,
    status,
  };
  const { data, success } = await apiMiddleware(changeContext, getBoothList, params);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
};

export const handleCreateBoothAPI = async ({ files, changeContext }: CreateBoothControllerAPIProps) => {
  if (files && !(files.type.match("zip.*") || files.type.match("rar.*"))) {
    contextParams.snackbar = true;
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = "檔案格式錯誤";
    changeContext.snackbarContext(contextParams);
    return { isSuccess: false };
  }

  const formData = new FormData();
  formData.append("file", files);
  const { data, success } = await apiMiddleware(changeContext, createBooth, formData);

  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "建立成功!";
    changeContext.snackbarContext(contextParams);
    return { isSuccess: success, ID: data.boothID };
  } else {
    return { isSuccess: success };
  }
};

export const handleGetBoothAPI = async ({
  boothID,
  setMainState,
  changeContext,
}: GetBoothControllerProps) => {
  const { data, success } = await apiMiddleware(changeContext, getBoothByID, boothID);

  if (success) {
    const { ID, content, createdAt,createdBy, email, objectData, files, name, status, privateURL, category } = data;
    setMainState({
      boothID: ID,
      initialContent: content,
      createdAt: createdAt,
      createdBy: createdBy,
      email: email,
      objectData: objectData,
      files: files,
      name: name,
      status: status,
      category: category,
      privateURL: privateURL,
    });
  }
};

export const handleUpdateBoothAPI = async ({
  boothID,
  thumbnail,
  name,
  email,
  content,
  createdBy,
  companyID,
  status,
  category,
  changeContext,
}: UpdateBoothControllerAPIProps) => {
  if (status < 3) {
    let thumbnailValue = thumbnail;
    if (typeof thumbnail === "object") {
      thumbnailValue = thumbnail.name;
    }
    const verifyArr = [
      isEmpty(name, "空間設計名稱"),
      // isEmptySelect(companyID, "公司"),
      isEmptySelect(createdBy, "創作者"),
      validEmail(email),
      isEmptyImg(thumbnailValue, "縮略圖"),
      isEmpty(content, "顯示內容"),
    ];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

    if (!isValidate) return;
  }

  const formData = new FormData();
  formData.append("file", thumbnail);
  formData.append("name", name);
  formData.append("email", email);
  formData.append("content", content);
  formData.append("createdBy", JSON.stringify(createdBy));
  // formData.append("companyID", JSON.stringify(companyID));
  formData.append("status", JSON.stringify(status));
  formData.append("category", JSON.stringify(category));

  const { data, success } = await apiMiddleware(changeContext, updateBooth, boothID, formData);
  if (success) {
    //setLocalStorage
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
  }

  return success;
};


export const handleUpdate3DDataControllerAPI = async ({
  booth3DData,
  boothID,
  changeContext,
}: UpdateBooth3DControllerAPIProps) => {
  const body = {saveData: booth3DData};
  const { success } = await apiMiddleware(changeContext, update3DBooth, boothID, body);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
  }

  return success;
}

export const handleDeleteBoothControllerAPI = async ({
  boothID,
  changeContext,
}: DeleteBoothControllerAPIProps) => {
  const { data, success } = await apiMiddleware(changeContext, deleteBooth, boothID);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "刪除成功!";
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleGetBoothCategoryAPI = async ({
  setMainState,
  changeContext,
}: GetBoothCategoryControllerProps) => {
  const { data, success } = await apiMiddleware(changeContext, getBoothCategory);

  if (success) {
    setMainState({
      boothCategory: data,
    });
  }
};
