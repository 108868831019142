import { useMediaQuery, useTheme } from "@material-ui/core";

export const getRoutesWithPath = (path: string, listArr: any[]) => {
  const splitPath = path.split("/");
  let newPath = path;
  let replaceItems = [];

  for (let index in splitPath) {
    const params = splitPath[index];
    if (params.includes(":")) {
      replaceItems.push(params);
    }
  }

  for (let index in replaceItems) {
    let params = replaceItems[index];
    let items = listArr[index];
    newPath = newPath.replace(params, items);
  }
  return newPath;
};

export const IsMobileSize = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return isMobileSize;
};

export const isAccessPermission = (userRole: number, reqID: number, currentID: number) => {
  //super admin role is 1
  if (userRole === 1) {
    return true;
  }

  if (reqID !== currentID) {
    return false;
  }
  if (currentID === 0) {
    return false;
  }

  return true;
};

export const validate3DFileFormat = (fileName: string) => {
  const splitFile = fileName.split(".");
  const fileType = splitFile[splitFile.length - 1];
  return fileType !== "" && fileType === "gltf";
};

export const checkLocalStorage = (localName: string) => {
  //delete more than 1 local storage
  if (window.localStorage.length > 1) {
    const currentKey = localName;
    for (var i = 1; i < window.localStorage.length; i++) {
      let key = window.localStorage.key(i);
      if (key !== currentKey && key !== null) {
        window.localStorage.removeItem(key);
      }
    }
  }
};
