import { useState } from "react";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router";
import { routes } from "../config/routes";
import { getRoutesWithPath } from "../utils";
//context
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useBackdropProps } from "../contexts/backdrop";
//components
import CustomHeaderTitle from "./component/CustomHeaderTitle";
import ModelLoader from "./component/ModelLoader";
import CustomButtons from "./component/CustomButtons";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Divider } from "@material-ui/core";
//controller
import { handleCreateBoothAPI } from "../controller/booth";
import { CreateBoothControllerAPIProps } from "../controller/booth/type";
import { handleCreateProductAPI } from "../controller/product";

const useStyles = makeStyles((theme) => ({
  display_container: {
    display: "flex",
    flexDirection: "column",
    padding: 35,
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
  dropzone: {
    position: "absolute",
    zIndex: 10,
    width: "100%",
    height: "100%",
  },
  drop_container: {
    // border: "1px solid",
    // borderColor: theme.palette.gray.dark,
    cursor: "pointer",
    textAlign: "center",
    border: "3px dashed #eeeeee",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    marginTop: "30px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  btn_container: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
  },
  file_container: {
    marginTop: 20,
  },
}));

const Upload3DPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setSnackbar } = useSnackbarProps();
  const { setBackdrop } = useBackdropProps();
  const isBooth = window.location.href.includes("booth");
  const isProduct = window.location.href.includes("product");
  const [file, setFile] = useState<File>();
  const [openSubmit, setOpenSubmit] = useState(true)

  const handleUpload3DFile = () => {
    const params: CreateBoothControllerAPIProps = {
      files: file,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };
    if (isBooth) {
      handleCreateBoothAPI(params).then(({ isSuccess, ID }) => {
        if (isSuccess) {
          history.push(getRoutesWithPath(routes.navBar.editBooth.path[1], [ID]));
          // history.push(routes.navBar.editBooth.path[0]);
        }
      });
    }
    if (isProduct) {
      handleCreateProductAPI(params).then(({ isSuccess, ID }) => {
        if (isSuccess) {
          history.push(getRoutesWithPath(routes.navBar.editProduct.path[1], [ID]));
          // history.push(routes.navBar.editBooth.path[0]);
        }
      });
    }
  };
  // DropZone functions
  const handleOnDrop = (acceptedFiles: any) => {
    if (!acceptedFiles.length) return;
    const tmpData = acceptedFiles.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        remarks: "",
      });
    });
    setFile(tmpData[0]);
    setOpenSubmit(false);
  };

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <CustomHeaderTitle title={"上傳檔案"} subTitle={""} />
      </div>
      <Paper square className={classes.root}>
        <div className={classes.display_container}>
          <div className={classes.drop_container}>
            {/* {file && <ModelLoader link={file} width={300} height={300} />} */}
            <Dropzone maxFiles={1} accept=".zip,.rar" onDrop={handleOnDrop}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p style={{ padding: "50px 0px" }}>Drag'n'drop files, or click to select files</p>
                </div>
              )}
            </Dropzone>
          </div>
          <div>
            {file && (
              <div className={classes.file_container}>
                選擇檔案： {file.name} {(file.size / 1000000).toFixed(2)}MB
              </div>
            )}
          </div>
          <div className={classes.btn_container}>
            <CustomButtons
              customStyle={{ marginBottom: 10 }}
              label={"建立"}
              handleClick={handleUpload3DFile}
              variant={"contained"}
              disabled={openSubmit}
              //   fullWidth
              isWithoutLinear
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};
export default Upload3DPage;
