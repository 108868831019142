export const validEmail = (email: string) => {
  let errMessage;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email))) {
    errMessage = '錯誤郵件格式.';
  }
  return errMessage;
};

export const isEmpty = (string: string|number|null, label='field') => {
  let errMessage;

  if (!string || string === '') {
    errMessage = label + "不能是空白.";
  }
  return errMessage;
};

export const isEmptySelect = (string: number|null, label='field') => {
  let errMessage;

  if (!string) {
    errMessage = "請選擇" + label;
  }
  return errMessage;
};

export const isEmptyImg = (string: string|null,label="圖示")=>{
  let errMessage;

  if (!string) {
    errMessage = label + "未上傳";
  }
  return errMessage;
};

export const comparePassword = (password: string, confirmPassword: string | undefined, isSame = false ) => {
  let errMessage;
  if (!password || password === '' || password.length === 0) {
    errMessage = "密碼不能為空白.";
  }

  if (!confirmPassword || confirmPassword === ''|| password.length === 0) {
    errMessage = "密碼不能為空白.";
  }
console.log("password",password,confirmPassword)
  if (isSame) {
    if (password === confirmPassword ) {
      errMessage = "新密碼不能與當前密碼相同.";
    }
  }
  else {
    if (password !== confirmPassword ) {
      errMessage = "新密碼和確認密碼不相同.";
    }
  }

  return errMessage
}
