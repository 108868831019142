import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import {
  createAccountWithIcon,
  deleteAccount,
  getAccountList,
  getAllAccount,
  getDetails,
  updateDetails,
  updateOthersDetails,
  updatePassword,
} from "../../services/admin";
import {
  GetAccountListAPIProps,
  UpdateDetailsAPIProps,
  UpdateOthersAPIProps,
  UpdatePasswordAPIProps,
} from "../../services/admin/type";
import { apiMiddleware } from "../../utils/middleware";
import { comparePassword, isEmpty, isEmptyImg, isEmptySelect } from "../../utils/validation";
import {
  GetDetailsControllerAPIProps,
  UpdateOthersControllerAPIProps,
  UpdateDetailsControllerAPIProps,
  UpdatePasswordControllerAPIProps,
  GetAccountListControllerAPIProps,
  CreateAccountControllerAPIProps,
  GetAllAccountControllerAPIProps,
  DeleteAccountControllerAPIProps,
} from "./type";

let contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetDetailsControllerAPI = async ({
  setMainState,
  changeContext,
}: GetDetailsControllerAPIProps) => {
  const { data, success } = await apiMiddleware(changeContext, getDetails);

  if (success) {
    const { ID, name, username, roleType, company, icon } = data;
    let newCompany = company;
    if (!newCompany) {
      newCompany = { id: 0, name: "", icon: "" };
    }
    setMainState({ ID, name, username, roleType, company: newCompany, icon });
  }
  return success;
};

export const handleUpdateDetailsControllerAPI = async ({
  file,
  name,
  username,
  roleType,
  companyID,
  user,
  changeContext,
}: UpdateDetailsControllerAPIProps) => {
  const verifyArr = [isEmpty(name, "名稱")];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

  if (!isValidate) return;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  formData.append("username", username);
  formData.append("roleType", JSON.stringify(roleType));
  formData.append("companyID", JSON.stringify(companyID));

  const { data, success } = await apiMiddleware(changeContext, updateDetails, formData);
  if (success) {
    let userDetails = { ...user };
    if (file) {
      userDetails = { ...user, user: { ...user.user, icon: file.preview } };
    }
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
    changeContext.userContext(userDetails);
  } else {
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = data.message;
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleUpdatePasswordControllerAPI = async ({
  oldPassword,
  newPassword,
  confirmPassword,
  changeContext,
}: UpdatePasswordControllerAPIProps) => {
  const verifyArr = [
    comparePassword(newPassword, confirmPassword),
    comparePassword(newPassword, oldPassword, true),
  ];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

  if (!isValidate) return;

  const body: UpdatePasswordAPIProps = {
    oldPassword,
    newPassword,
  };

  const { data, success } = await apiMiddleware(changeContext, updatePassword, body);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
  } else {
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = data.message;
    changeContext.snackbarContext(contextParams);
  }
  return success;
};
/* designer*/
export const handleGetAccountListControllerAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  companyID,
  setMainState,
  changeContext,
}: GetAccountListControllerAPIProps) => {
  const params: GetAccountListAPIProps = {
    page,
    limit,
    order,
    orderBy,
    search,
    companyID,
  };

  const { data, success } = await apiMiddleware(changeContext, getAccountList, params);

  if (success) {
    const { list, total } = data;
    setMainState({ list: list, total: total });
  }
  return success;
};

export const handleCreateAccountControllerAPI = async ({
  file,
  name,
  username,
  roleType,
  companyID,
  password,
  confirmPassword,
  changeContext,
}: CreateAccountControllerAPIProps) => {
  const verifyArr = [
    isEmptyImg(file.path, "圖示"),
    isEmpty(name, "管理者名稱"),
    isEmpty(username, "管理者帳號"),
    isEmptySelect(companyID, "公司"),
    comparePassword(password, confirmPassword),
    // comparePassword(password, confirmPassword, true),
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

  if (!isValidate) return;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  formData.append("username", username);
  formData.append("roleType", JSON.stringify(roleType));
  formData.append("companyID", JSON.stringify(companyID));
  formData.append("password", password);

  const { data, success } = await apiMiddleware(changeContext, createAccountWithIcon, formData);

  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "建立成功!";
    changeContext.snackbarContext(contextParams);
    // const { ID, name, username } = data;
    // setMainState({ ID, name, username });
  }
  return success;
};

/* only done by admin */
export const handleUpdateOthersControllerAPI = async ({
  ID,
  file,
  name,
  username,
  roleType,
  companyID,
  password,
  confirmPassword,
  changeContext,
}: UpdateOthersControllerAPIProps) => {

  const verifyArr = [
    isEmpty(name, "管理者名稱"),
    isEmpty(username, "管理者帳號"),
    isEmptySelect(companyID, "公司"),
    password && comparePassword(password, confirmPassword),
  ];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

  if (!isValidate) return;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  formData.append("username", username);
  formData.append("roleType", JSON.stringify(roleType));
  formData.append("companyID", JSON.stringify(companyID));
  formData.append("password", password);

  const { data, success } = await apiMiddleware(changeContext, updateOthersDetails, ID, formData);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
  } else {
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = data.message;
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleGetAllAccountControllerAPI = async (setMainState: any, changeContext: any) => {
  const { data, success } = await apiMiddleware(changeContext, getAllAccount);
  if (success) {
    let arr: any = [];
    data.map((i: any) => {
      let obj = { value: i.ID, label: i.name, company: i.company };
      arr.push(obj);
    });

    setMainState({ accountList: arr });
  }
  return success;
};

export const handleDeleteAccountControllerAPI = async ({
  ID,
  changeContext,
}: DeleteAccountControllerAPIProps) => {
  const { data, success } = await apiMiddleware(changeContext, deleteAccount, ID);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "刪除成功!";
    changeContext.snackbarContext(contextParams);
  }
  return success;
};
