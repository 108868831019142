import { login, logout } from "../../services/admin";
import { LoginAPIProps } from "../../services/admin/type";
import { IApp, IUser } from "../../types/app";
import { apiMiddleware, ContextProps } from "../../utils/middleware";
import { LoginControllerAPIProps } from "./type";

let contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

const userContext: IApp = {
  isLogin: false,
  user: {
    username: "",
    email: "",
    role: 0,
    userID: 0,
  },
};
export const handleLoginAPI = async ({
  username,
  password,
  rememberAcc,
  changeContext,
}: LoginControllerAPIProps) => {
  const params: LoginAPIProps = {
    username,
    password,
  };
  const { data, success } = await apiMiddleware(changeContext, login, params);

  if (success) {
    const { ID, username, name, company, icon, roleType } = data;
    userContext.user = {
      userID: ID,
      username: username,
      email: "",
      role: roleType,
      icon: icon,
      company: company,
    };
    userContext.isLogin = true;
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "登入成功!";
    changeContext.userContext(userContext);
    changeContext.snackbarContext(contextParams);
  } else {
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = data.message;
    userContext.user = { userID: 0, username: "", email: "", role: 0, icon: "" };
    changeContext.userContext(userContext);
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleLogoutAPI = async (changeContext: ContextProps) => {
  const { data, success } = await apiMiddleware(changeContext, logout);
  if (!success) return;
  else {
    //setLocalStorage
    userContext.user = { userID: 0, username: "", email: "", role: 0, icon: "" };
    userContext.isLogin = false;
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "登出成功!";
    changeContext.snackbarContext(contextParams);
    changeContext.userContext(userContext);
    return true;
  }
};
