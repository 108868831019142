import fetchData from "../../utils/fetchData";
import {
  CreateAccountAPIProps,
  GetAccountListAPIProps,
  LoginAPIProps,
  UpdateDetailsAPIProps,
  UpdateOthersAPIProps,
  UpdatePasswordAPIProps,
} from "./type";

export function login(body: LoginAPIProps) {
  return fetchData({
    url: "/admin/login",
    method: "POST",
    data: body,
  });
}

export function logout() {
  return fetchData({
    url: "/admin/logout",
    method: "POST",
  });
}

export function getDetails() {
  return fetchData({
    url: "/admin/details",
    method: "GET",
  });
}

export function updateDetails(body: UpdateDetailsAPIProps) {
  return fetchData({
    url: "/admin/details",
    method: "PUT",
    data: body,
  });
}

export function updatePassword(body: UpdatePasswordAPIProps) {
  return fetchData({
    url: "/admin/password",
    method: "PUT",
    data: body,
  });
}

export function createAccountWithIcon(body: CreateAccountAPIProps) {
  return fetchData({
    url: "/admin/create",
    method: "POST",
    data: body,
  });
}

export function updateOthersDetails(ID: number, body: UpdateOthersAPIProps) {
  return fetchData({
    url: `/admin/details/${ID}`,
    method: "PUT",
    data: body,
  });
}

export function getAccountList(params: GetAccountListAPIProps) {
  return fetchData({
    url: "/admin/list",
    method: "GET",
    params: params,
  });
}

export function getAllAccount() {
  return fetchData({
    url: "/admin/all",
    method: "GET",
  });
}

export function deleteAccount(userID: number) {
  return fetchData({
    url: `/admin/details/${userID}`,
    method: "DELETE",
  });
}
