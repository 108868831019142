import React from "react";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

type HeaderTitleProps = {
  title: string;
  subTitle: string;
};

const useStyles = makeStyles((theme) => ({
  header_container: {},
  header_title: {
    fontSize: "26px",
  },
  subheader_title: {
    fontSize: 14,
    color: theme.palette.gray.dark, //#9b9da4
    // marginBottom: 34,
    // [theme.breakpoints.down("md")]: {
    //   marginBottom: 20,
    // },
  },
}));

const CustomHeaderTitle: React.FC<HeaderTitleProps> = ({ title, subTitle }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography className={classes.header_title}>{title}</Typography>
      <Typography className={classes.subheader_title}>{subTitle}</Typography>
    </React.Fragment>
  );
};
export default CustomHeaderTitle;
