import React from "react";
import { useHistory } from "react-router";
import { routes } from "../../../config/routes";
import { useProps } from "../../../contexts";
import { useSnackbarProps } from "../../../contexts/snackbar/SnackbarContext";
//Component
import HamburgerDrawer from "./HamburgerDrawer";
// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Typography, Avatar, IconButton, Menu } from "@material-ui/core";
import DownArrow from "@material-ui/icons/KeyboardArrowDownRounded";
import LogOutIcon from "@material-ui/icons/PowerSettingsNew";
//Assets
import ImageProfile from "../../../assets/profile_image.jpeg";
import { handleLogoutAPI } from "../../../controller/login";
import { useBackdropProps } from "../../../contexts/backdrop";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxShadow: "0 3px 15px 0 rgba(109, 109, 109, 0.16)",
    backgroundColor: "white",
    zIndex: 10,
    height: "67px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftToolWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  toolWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: 0,
    marginTop: 2,
    [theme.breakpoints.up("lg")]: {
      marginRight: 40,
    },
  },
  img_size: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    width: "30px",
    height: "30px",
    backgroundColor: theme.palette.gray_2.light,
  },
  greetingText: {
    fontSize: 13,
    margin: 0,
    marginLeft: 16,
    textTransform: "none",
    color: theme.palette.gray.dark,
    [theme.breakpoints.down("md")]: {
      flex: 1,
    },
  },
  greetingTextName: {
    fontSize: 13,
    margin: 0,
    marginLeft: 16,
    textTransform: "none",
    color: "#000",
    [theme.breakpoints.down("md")]: {
      flex: 1,
    },
  },
  menu_font: {
    fontSize: 16,
  },
  icon: {
    fontSize: "25px",
  },
  search_bar_container: {
    flex: 1,
    justifyContent: "flex-start",
    marginLeft: 140,
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  horizontal_divider: {
    margin: "0px 10px 0px 20px",
    width: 2,
    height: 30,
    backgroundColor: theme.palette.gray.light,
  },
}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { setSnackbar } = useSnackbarProps();
  const { user, _handleChange } = useProps();
  const { setBackdrop } = useBackdropProps();
  const isSizeDevice = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogout = (event: any) => {
    handleLogoutAPI({
      snackbarContext: setSnackbar,
      backdropContext: setBackdrop,
      userContext: _handleChange,
    }).then((isSuccess) => {
      if (isSuccess) {
        history.push(routes.generals.login.path);
      }
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftToolWrapper}>{isSizeDevice && <HamburgerDrawer />}</div>
      <div className={classes.search_bar_container}></div>

      <div className={classes.toolWrapper}>
        <Avatar className={classes.img_size} src={user.icon}>
          {/* <div style={{ marginTop: 3 }}>{user.username ? user.username.slice(0, 1) : "A"}</div> */}
        </Avatar>
        <div>
          <Typography className={classes.greetingText}>Welcome, </Typography>
          <Typography className={classes.greetingTextName}>{user.username}</Typography>
        </div>

        {/* <Button aria-controls="customized-menu" onClick={handleClick}>
          <DownArrow className={classes.icon} />
        </Button> */}
        <div className={classes.horizontal_divider} />
        <IconButton aria-controls="customized-menu" onClick={handleLogout}>
          <LogOutIcon className={classes.icon} />
        </IconButton>
        <div></div>
      </div>
    </div>
  );
};

export default Header;
