import React from "react";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  search: {
    display: "flex",
    alignItems: "center",
    borderRadius: 7,
    backgroundColor: theme.palette.common.white,
    // '&:hover': {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    height: 40,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  search_min_width: {
    minWidth: 700,
    [theme.breakpoints.down(1281)]: {
      minWidth: 500,
    },
  },
  searchIcon: {
    padding: "0 10px",
    height: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: theme.palette.gray.main,
    width: 22,
    height: 22,
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
    fontSize: 15,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  dark_color: {
    backgroundColor: theme.palette.gray.light,
  },
  dark_text_color: {
    color: theme.palette.gray.main,
  },
}));

type SearchBarProps = {
  input: string;
  placeHolder: string;
  handleChange: any;
  isDark?: boolean;
  isMaxWidth?: boolean;
  submitSearch?: (searchParams: string) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
  input,
  handleChange,
  placeHolder,
  isDark = false,
  isMaxWidth = true,
  submitSearch = () => {},
}) => {
  const classes = useStyles();
  const handleSearch = () => {
    submitSearch(input);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitSearch(input);
    }
  };
  return (
    <div
      className={clsx(classes.search, isDark && classes.dark_color, !isMaxWidth && classes.search_min_width)}
    >
      <div className={classes.searchIcon} onClick={handleSearch}>
        <SearchIcon className={classes.icon} />
      </div>
      <InputBase
        placeholder={placeHolder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onKeyPress={handleKeyPress}
        value={input}
        onChange={handleChange}
      />
    </div>
  );
};
export default SearchBar;
