import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IsMobileSize } from "../utils";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
//context
import { useProps } from "../contexts";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useBackdropProps } from "../contexts/backdrop";
//Component
import CustomHeaderTitle from "./component/CustomHeaderTitle";
import CustomButtons from "./component/CustomButtons";
import CustomTextInput from "./component/CustomTextInput";
import CustomSearchSelect from "./component/CustomSearchSelect";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Divider, Avatar, Grid, Radio, Typography } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

//Contoller
import { handleGetAllCompanyAPI } from "../controller/company";
import {
  GetDetailsControllerAPIProps,
  UpdateDetailsControllerAPIProps,
  UpdatePasswordControllerAPIProps,
} from "../controller/admin/type";
import {
  handleGetDetailsControllerAPI,
  handleUpdateDetailsControllerAPI,
  handleUpdatePasswordControllerAPI,
} from "../controller/admin";

//Assets
import ImageProfile from "../assets/profile_image.jpeg";

const useStyles = makeStyles((theme) => ({
  display_flex: {
    display: "flex",
  },
  display_container: {
    display: "flex",
    flexDirection: "column",
    padding: 35,
  },
  form_container: {
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  form_inner_container: {
    flex: 0.5,
  },
  btn_container: {
    marginTop: 49,
    justifyContent: "flex-end",
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
  right_title: {
    fontSize: 17,
    color: "#000",
    // marginBottom: 12,
    fontWeight: 600,
  },
  spacer: {
    marginTop: 19,
    textAlign: "left",
  },
  list_root: {
    cursor: "pointer",
    padding: 0,
    "& .MuiListItem-root": {
      marginBottom: 21,
    },
    "& .Mui-selected, .Mui-selected:hover": {
      backgroundColor: "#ffebe6",
      "& .MuiSvgIcon-root": { fill: theme.palette.primary.main },
      "& .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: "#ffebe6", //hover
      color: theme.palette.primary.main, //hover
    },
  },
  img_container: {
    position: "relative",
  },
  img_size: {
    width: "84px",
    height: "84px",
    "&:hover": {
      background: "black",
    },
  },
  overlay: {
    position: "absolute",
    display: "flex",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 60,
    opacity: 0,
    transition: ".5s ease",
    backgroundColor: "rgba(0,0,0,0.5)",
    "&:hover": {
      opacity: 1,
    },
    cursor: "pointer",
  },
  camera_icon: {
    color: "white",
  },
  sub_title_container: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "20px 0px 0px 0px",
  },
  divider: {
    margin: "10px 0px 5px 0px",
  },
  title_font: {
    marginBottom: 10,
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.gray_1.dark,
  },
  title_divider_left: {
    flex: 0.1,
    [theme.breakpoints.between(325,1280)]: {
      flex: 0.2,
    },
  },
  title_divider_right: {
    flex: 0.9,
    [theme.breakpoints.between(325,1280)]: {
      flex: 0.8,
    },
  },
}));

const SettingPage: React.FC = () => {
  const classes = useStyles();
  const { isLogin, user, _handleChange } = useProps();
  const { setSnackbar } = useSnackbarProps();
  const { setBackdrop } = useBackdropProps();
  const [mainState, _setMainState] = useState({
    icon: "",
    file: "",
    fileName: "",
    name: "",
    username: "",
    company: { id: 0, name: "", icon: "" },
    companyList: [{ value: 0, label: "" }],
    roleType: 0,
    password: "",
    newPassword: "",
    confirmPassword: "",
    refreshData: false,
  });

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRefreshData = () => {
    setMainState({ file: "", refreshData: !mainState.refreshData });
  };

  const _handleGetDetailsAPI = () => {
    const params: GetDetailsControllerAPIProps = {
      setMainState: setMainState,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };
    handleGetDetailsControllerAPI(params);
  };

  const handleChange = (event: any, stateKey: string) => {
    setMainState({ [stateKey]: event.target.value });
  };

  const handleSubmit = () => {
    const params: UpdateDetailsControllerAPIProps = {
      file: mainState.file,
      username: mainState.username,
      name: mainState.name,
      roleType: mainState.roleType,
      companyID: mainState.company.id,
      user: { isLogin, user },
      changeContext: {
        userContext: _handleChange,
        snackbarContext: setSnackbar,
        backdropContext: setBackdrop,
      },
    };
    handleUpdateDetailsControllerAPI(params).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
      }
    });
  };
  const handleSubmitPassword = () => {
    const params: UpdatePasswordControllerAPIProps = {
      oldPassword: mainState.password,
      newPassword: mainState.newPassword,
      confirmPassword: mainState.confirmPassword,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };
    handleUpdatePasswordControllerAPI(params).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        setMainState({ password: "", newPassword: "", confirmPassword: "" });
      }
    });
  };

  const handleDropdownList = (event: { value: string | number; label: string }, statusKey: string) => {
    // const handleDropdownList = (event: any) => {
    const value = event.value;
    setMainState({ ...mainState, company: { ...mainState.company, ["id"]: value } });
  };

  // DropZone functions
  const handleOnDrop = (acceptedFiles: any) => {
    if (!acceptedFiles.length) return;
    const tmpData = acceptedFiles.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        remarks: "",
      })
    );
    setMainState({ file: tmpData[0], fileName: tmpData[0].preview });
  };

  const handleDropRejected = (files: any) => {
    if (!files.length) return;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: handleDropRejected,
    multiple: false,
    accept: "image/*",
  });

  useEffect(() => {
    _handleGetDetailsAPI();
    handleGetAllCompanyAPI(setMainState, setSnackbar);
  }, [mainState.refreshData]);

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <CustomHeaderTitle title={"全部設定"} subTitle={"改變你的帳戶設定"} />
      </div>

      <Paper square className={classes.root}>
        <div className={classes.display_container}>
          <div className={classes.sub_title_container}>
            <div className={classes.title_divider_left}>
              <div className={classes.right_title}>帳戶設定</div>
            </div>
            <div className={classes.title_divider_right}>
              <Divider className={classes.divider} />
            </div>
          </div>
          <div className={clsx(classes.display_flex, classes.form_container)}>
            <div className={classes.form_inner_container}>
              <div style={{ display: "flex", marginTop: 27 }}>
                <Typography style={{ fontSize: 13, marginRight: 22 }}>圖示</Typography>
                <div className={classes.img_container} onClick={open}>
                  {!mainState.file ? (
                    <Avatar
                      onClick={open}
                      className={classes.img_size}
                      src={mainState.icon ? mainState.icon : ImageProfile}
                    />
                  ) : (
                    <Avatar onClick={open} className={classes.img_size} src={mainState.fileName} />
                  )}
                  <div className={classes.overlay}>
                    <PhotoCameraIcon className={classes.camera_icon} />
                  </div>
                </div>
              </div>
              <CustomTextInput
                variant="outlined"
                value={mainState.username}
                title="帳戶名稱"
                type="text"
                stateKey="name"
                disabled
                handleChange={handleChange}
              />
              <CustomTextInput
                variant="outlined"
                value={mainState.name}
                title="名稱"
                type="text"
                stateKey="name"
                placeholder="Enter your name"
                handleChange={handleChange}
              />
              <div className={classes.spacer}>
                <div className={classes.title_font}>公司</div>
                <CustomSearchSelect
                  // defaultLabel={"-"}
                  defaultLabel={"選擇公司"}
                  initValue={mainState.company.id}
                  stateKey={"companyID"}
                  handleChange={handleDropdownList}
                  listArray={mainState.companyList}
                  hasSearch={false}
                />
              </div>

              <div className={clsx(classes.display_flex, classes.btn_container)}>
                <CustomButtons
                  label="更新"
                  customStyle={{ marginLeft: 15, fontSize: 13, maxWidth: 100 }}
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          <div className={classes.sub_title_container}>
          <div className={classes.title_divider_left}>
              <div className={classes.right_title}>重置密碼</div>
            </div>
            <div className={classes.title_divider_right}>
              <Divider className={classes.divider} />
            </div>
          </div>
          <div className={clsx(classes.display_flex, classes.form_container)}>
            <div className={classes.form_inner_container}>
              <CustomTextInput
                variant="outlined"
                value={mainState.password}
                title="當前密碼"
                type="password"
                stateKey="password"
                disabled
                handleChange={handleChange}
              />
              <CustomTextInput
                variant="outlined"
                value={mainState.newPassword}
                title="新密碼"
                type="password"
                stateKey="newPassword"
                handleChange={handleChange}
              />
              <CustomTextInput
                variant="outlined"
                value={mainState.confirmPassword}
                title="確定密碼"
                type="password"
                stateKey="confirmPassword"
                handleChange={handleChange}
              />
              <div className={clsx(classes.display_flex, classes.btn_container)}>
                <CustomButtons
                  label="更新"
                  customStyle={{ marginLeft: 15, fontSize: 13, maxWidth: 100 }}
                  handleClick={handleSubmitPassword}
                />
              </div>
            </div>
          </div>
          {/* </div> */}
          <div {...getRootProps()}>
            <input {...getInputProps()} />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default SettingPage;
