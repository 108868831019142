import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
//Component
import CustomTextInput from "../CustomTextInput";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import UploadFileIcon from "@material-ui/icons/AddCircle";
//img
import { Divider, FormControlLabel, Grid, Radio, Checkbox, Typography } from "@material-ui/core";
import clsx from "clsx";
import CustomButtons from "../CustomButtons";

//Those Pages need >> Admin Page
//Use for create and edit admin

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    "& .MuiPaper-root": {
      backgroundColor: "white",
    },
  },
  dialog_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 50px 40px 50px",
    minWidth: "30vw",
    backgroundColor: "white",
  },
  input_container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  input_title: {
    margin: "5px 0px",
    fontSize: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  sub_title_container: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "20px 0px 5px 0px",
  },
  sub_title: {
    // flex: 0.2,
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  submit_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "20px 50px",
  },
  delete_button_container: {
    display: "flex",
    justifyContent: "space-between",
  },
  img_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    // height: 350,
    // maxHeight: 250,
    width: 350,
    height:250,
    marginTop: 20,
  },
  img_size: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  dropzone: {
    // display: "none",
    position: "absolute",
    zIndex: 10,
    width: "100%",
    height: "100%",
  },
  spacer: {
    marginTop: 19,
    textAlign: "left",
  },
}));

type CreateCompanyPopUpProps = {
  handleSubmit: (value: any, isCreate: boolean) => void;
};

const CreateCompanyPopUp = React.forwardRef<any, CreateCompanyPopUpProps>(({ handleSubmit }, ref) => {
  const classes = useStyles();

  const [mainState, _setMainState] = useState({
    //TODO: add type
    ID: 0,
    // icon:"",
    file: {},
    fileName:"",
    name: "",
    isOpen: false,
    isCreate: true,
  });

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      isOpen: false,
      ID: 0,
      name: "",
      file: {},
      fileName:"",
      isCreate: true,
    });
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  const _handleSubmit = () => {
    const returnParam = {
      ID: mainState.ID,
      file: mainState.file,
      name: mainState.name,
    };
    handleSubmit(returnParam, mainState.isCreate);
  };

  // DropZone functions
  const handleOnDrop = (acceptedFiles: any) => {
    if (!acceptedFiles.length) return;
    const tmpData = acceptedFiles.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        remarks: "",
      })
    );
    setMainState({ file:tmpData[0], fileName: tmpData[0].preview });
  };

  const handleDropRejected = (files: any) => {
    if (!files.length) return;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: handleDropRejected,
    multiple: false,
  });

  return (
    <Dialog
      className={classes.dialog}
      open={mainState.isOpen}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        <div className={classes.dialog_container}>
          <div className={classes.input_container}>
            <div className={classes.delete_button_container}>
              <p className={classes.input_title}>{`${mainState.isCreate ? "添加" : "編輯"}公司`}</p>
            </div>
            <div className={classes.sub_title_container}>
              <Grid container spacing={2}>
                <Grid xs={12} md={12} item>
                  <CustomTextInput
                    variant="outlined"
                    value={mainState.name}
                    title="公司名稱"
                    type="text"
                    stateKey="name"
                    placeholder="輸入公司名稱"
                    handleChange={(event) => setMainState({ name: event.target.value })}
                  />
                  <div className={classes.spacer} style={{ position: "relative" }}>
                    <CustomButtons
                      frontIconChildren={<UploadFileIcon />}
                      label={"上傳圖示"}
                      handleClick={open}
                      variant={"contained"}
                      fullWidth
                      // isWithoutLinear
                    />
                    {mainState.fileName && (
                      <div className={classes.img_container}>
                        <img className={classes.img_size} src={mainState.fileName} alt="" />
                      </div>
                    )}
                    <div style={{display:"none"}} {...getRootProps({ className: classes.dropzone })}>
                      <input {...getInputProps()} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.submit_container}>
          <CustomButtons
            label="取消"
            variant="Standard"
            handleClick={handleClose}
            customStyle={{ fontSize: 14, paddingLeft: 40, paddingRight: 40 }}
          />
          <CustomButtons
            label={mainState.isCreate ? "添加" : "編輯"}
            handleClick={_handleSubmit}
            customStyle={{ fontSize: 14, paddingLeft: 40, paddingRight: 40, marginLeft: 10 }}
          />
        </div>
      </div>
    </Dialog>
  );
});

export default CreateCompanyPopUp;
