import fetchData from "../../utils/fetchData";
import { CreateProductAPIProps, GetProductListAPIProps, UpdateProductAPIProps } from "./type";

export function getProductList(params: GetProductListAPIProps) {
  return fetchData({
    url: "/product",
    method: "GET",
    params: params,
  });
}

export function getSpecialProductList(params: GetProductListAPIProps) {
  return fetchData({
    url: "/special-product",
    method: "GET",
    params: params,
  });
}

export function createProduct(body: CreateProductAPIProps) {
  return fetchData({
    url: "/product/create-3d",
    method: "POST",
    data: body,
  });
}

export function getProductByID(productID: number) {
  return fetchData({
    url: `/product/${productID}`,
    method: "GET",
  });
}

export function updateProduct(productID: number, body: UpdateProductAPIProps) {
  return fetchData({
    url: `/product/${productID}`,
    method: "PUT",
    data: body,
  });
}

export function deleteProduct(productID: number) {
  return fetchData({
    url: `/product/${productID}`,
    method: "DELETE",
  });
}

export function getProductCategory() {
  return fetchData({
    url: `/product-category`,
    method: "GET",
  });
}

export function update3DProduct(productID: number, body: string) {
  return fetchData({
    url: `/product/save-3d/${productID}`,
    method: "PUT",
    data: body,
  });
}