import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import {
  createCompany,
  deleteCompany,
  getAllCompany,
  getCompany,
  getCompanyPagination,
  updateCompany,
} from "../../services/company";
import { GetCompanyListAPIProps } from "../../services/company/type";
import { apiMiddleware } from "../../utils/middleware";
import { isEmpty, isEmptyImg } from "../../utils/validation";
import {
  CreateAdminControllerAPIProps,
  DeleteCompanyControllerAPIProps,
  GetCompanyByIDControllerAPIProps,
  GetCompanyListControllerAPIProps,
  UpdateAdminControllerAPIProps,
} from "./type";

let contextParams: { [key: string]: any } = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetCompanyListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  changeContext,
  setMainState,
}: GetCompanyListControllerAPIProps) => {
  const params: GetCompanyListAPIProps = {
    page,
    limit,
    order,
    orderBy,
    search,
  };
  const { data, success } = await apiMiddleware(changeContext, getCompanyPagination, params);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
};

export const handleCreateCompanyControllerAPI = async ({
  file,
  name,
  changeContext,
}: CreateAdminControllerAPIProps) => {
  const verifyArr = [isEmpty(name, "公司名稱"), isEmptyImg(file.path, "圖示")];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

  if (!isValidate) return;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);

  const { data, success } = await apiMiddleware(changeContext, createCompany, formData);

  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "添加成功!";
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleGetCompanyByID = async ({
  ID,
  changeContext,
  setMainState,
}: GetCompanyByIDControllerAPIProps) => {
  const { data, success } = await apiMiddleware(changeContext, getCompany, ID);
  if (success) {
    const { ID, name, icon, createdAt } = data;
    setMainState({
      ID: ID,
      name,
      fileName: icon,
      createdAt,
    });
  }
};

export const handleUpdateCompanyControllerAPI = async ({
  ID,
  file,
  name,
  changeContext,
}: UpdateAdminControllerAPIProps) => {
  const verifyArr = [isEmpty(name, "公司名稱"), isEmptyImg(file, "公司圖示")];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext.snackbarContext);

  if (!isValidate) return;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);

  const { data, success } = await apiMiddleware(changeContext, updateCompany, ID, formData);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "更新成功!";
    changeContext.snackbarContext(contextParams);
  } else {
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = data.message;
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleDeleteCompanyControllerAPI = async ({
  ID,
  changeContext,
}: DeleteCompanyControllerAPIProps) => {
  const { data, success } = await apiMiddleware(changeContext, deleteCompany, ID);
  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "刪除成功!";
    changeContext.snackbarContext(contextParams);
  }
  return success;
};

export const handleGetAllCompanyAPI = async (setMainState: any, changeContext: any) => {
  const { data, success } = await apiMiddleware(changeContext, getAllCompany);
  if (success) {
    let arr: any = [];
    data.map((i: any) => {
      let obj = { value: i.ID, label: i.name };
      arr.push(obj);
    });
    setMainState({ companyList: arr });
    // setCompantList(arr);
  }
};

export const getCompanyNameByID = (companyList: { label: string; value: number }[], companyID: number) => {
  // export const getCompanyNameByID = (companyList: any, companyID: number) => {
  let name = "";

  if (companyList.length === 0) return name;

  companyList.map((item: any) => {
    if (item.value === companyID) {
      name = item.label.toString();
    }
  });
  return name;
};
