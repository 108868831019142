import React, { useEffect, useRef, useState } from "react";
import { useProps } from "../contexts";
import { useSnackbarProps } from "../contexts/snackbar/SnackbarContext";
import { useBackdropProps } from "../contexts/backdrop/index";
import useQuery from "../hooks/useQuery";
import { useHistory } from "react-router";
//Material UI
import { Divider, Avatar, Checkbox, Grid, IconButton, makeStyles } from "@material-ui/core";
import UploadFileIcon from "@material-ui/icons/AddCircle";

//Component
import CustomHeaderTitle from "./component/CustomHeaderTitle";
import CustomTable from "./component/CustomTable";
import RedirectText from "./component/RediractText";
import SearchBar from "./component/SearchBar";
import DeletePopUp from "./component/popup/DeletePopUp";
import CustomButtons from "./component/CustomButtons";
import CreateCompanyPopUp from "./component/popup/CreateCompanyPopUp";
//Assets
import editImg from "../assets/edit_1.png";
import deleteImg from "../assets/Delete.png";
// import showImg from "../assets/showImg/ShowImg1.png";
// import showImg2 from "../assets/showImg/ShowImg2.png";
//controller
import {
  handleCreateCompanyControllerAPI,
  handleDeleteCompanyControllerAPI,
  handleGetCompanyByID,
  handleGetCompanyListAPI,
  handleUpdateCompanyControllerAPI,
} from "../controller/company";
import {
  CreateAdminControllerAPIProps,
  DeleteCompanyControllerAPIProps,
  GetCompanyByIDControllerAPIProps,
  GetCompanyListControllerAPIProps,
  UpdateAdminControllerAPIProps,
} from "../controller/company/type";

const useStyles = makeStyles((theme) => ({
  title_font: {
    fontSize: 15,
    fontWeight: "bold",
    // margin: "35px 0px 10px 0px",
  },
  icon_btn_ctn: {
    margin: "0px 5px",
    width: 30,
    height: 30,
    backgroundColor: theme.palette.gray_3.light,
  },
  checkbox_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 15,
  },
  search_container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button_container: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sub_title_container: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "20px 0px",
  },
  sub_title_container_text: {
    flex: 0.1,
    [theme.breakpoints.between(325,1280)]: {
      flex: 0.2,
    },
  },
  sub_title_container_line: {
    flex: 0.9,
    [theme.breakpoints.between(325,1280)]: {
      flex: 0.8,
    },
  },
  divider: {
    margin: "10px 0px 5px 0px",
  },
  thumbnailImage: {
    width: 40,
    height: 40,
    objectFit: "cover",
  },
}));

const generateBody = (
  classes: any,
  body: Array<any>,
  page: number,
  rowsPerPage: number,
  handleDelete: any,
  handleSelect: any
) => {
  return body.map((e: any, i) => ({
    id: <div style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}</div>,
    // checkBox: (
    //   <Checkbox
    //     style={{ transform: "scale(0.8)", marginRight: 5, marginLeft: -20 }}
    //     size="small"
    //     color="primary"
    //     onChange={(event) => {
    //       _handleCheckItem(e, event.target.checked);
    //     }}
    //   />
    // ),
    icon: e.icon ? (
      <img className={classes.thumbnailImage} src={e.icon} alt="image" />
    ) : (
      <Avatar>{e.name ? e.name.charAt(0) : ""}</Avatar>
    ),
    name: <RedirectText id={e.ID} text={e.name} handleClick={() => {}} />,
    createdAt: e.createdAt,
    button: (
      <div>
        <IconButton className={classes.icon_btn_ctn} onClick={() => handleSelect(e.ID)} aria-label="edit">
          <img style={{ width: 12 }} src={editImg} />
        </IconButton>
        <IconButton
          className={classes.icon_btn_ctn}
          onClick={() => handleDelete(e.ID, e.name)}
          aria-label="edit"
        >
          <img style={{ width: 12 }} src={deleteImg} />
        </IconButton>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  // { id: "checkBox", label: "", align: "right" },
  { id: "icon", label: "圖示", align: "left" },
  { id: "name", label: "名稱", align: "left" },
  { id: "createdAt", label: "創建時間 ", align: "left" },
  { id: "button", label: "", align: "left" },
];

const CompanyListPage: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = useQuery();
  const { user,_handleChange } = useProps();
  const { setSnackbar } = useSnackbarProps();
  const { setBackdrop } = useBackdropProps();
  const deletePopUp = useRef<any>(null);
  const companyPopUpRef = useRef<any>(null);
  const [mainState, _setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: query.search || "",
    refreshData: false,
  });

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };
  
  const handleDelete = (ID: number, name: string) => {
    deletePopUp.current.handleOpen();
    deletePopUp.current.setMainState({ ID: ID, name: name, reqRole: 1, role: user.role, userID: user.userID });
  };

  const _handleDelete = (ID: number) => {
    const params: DeleteCompanyControllerAPIProps = {
      ID: ID,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
    };
    handleDeleteCompanyControllerAPI(params).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deletePopUp.current.handleClose();
      }
    });
  };
  const handleSubmit = (value: any, isCreate: boolean) => {
    if (isCreate) {
      const param: CreateAdminControllerAPIProps = {
        ...value,
        changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      };
      handleCreateCompanyControllerAPI(param).then((isSuccess) => {
        if (isSuccess) {
          companyPopUpRef.current.handleClose();
          handleRefreshData();
        }
      });
    } else {
      const param: UpdateAdminControllerAPIProps = {
        ...value,
        changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      };
      handleUpdateCompanyControllerAPI(param).then((isSuccess) => {
        if (isSuccess) {
          companyPopUpRef.current.handleClose();
          handleRefreshData();
        }
      });
    }
  };
  const handleSelect = (ID: number) => {
    const params: GetCompanyByIDControllerAPIProps = {
      ID: ID,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      setMainState: companyPopUpRef.current.setMainState,
    };
    handleGetCompanyByID(params);
    companyPopUpRef.current.handleOpen();
    companyPopUpRef.current.setMainState({ isCreate: false });
  };

  const handleCreate = () => {
    companyPopUpRef.current.handleOpen();
    companyPopUpRef.current.setMainState({ isCreate: true });
  };

  const _handleGetCompanyList = () => {
    const params: GetCompanyListControllerAPIProps = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
      setMainState: setMainState,
    };
    let obj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
    };

    setQuery(obj);
    handleGetCompanyListAPI(params);
  };
  //API
  const fetchList = async (search: any = undefined) => {
    _handleGetCompanyList();
  };

  const handleChangeSearch = async (search: any = undefined) => {
    await fetchList(search);
  };

  useEffect(() => {
    fetchList();
  }, [mainState.orderBy, mainState.order, mainState.page, mainState.refreshData]);

  const body = generateBody(
    classes,
    mainState.list,
    mainState.page,
    mainState.limit,
    handleDelete,
    handleSelect
  );

  return (
    <React.Fragment>
      <div style={{ marginBottom: 20 }}>
        <CustomHeaderTitle title="公司列表" subTitle={`瀏覽所有公司`} />
      </div>
      <Grid spacing={3} container>
        <Grid className={classes.search_container} sm={12} md={6} lg={6} item>
          <SearchBar
            input={mainState.search}
            placeHolder={"搜索"}
            submitSearch={handleChangeSearch}
            handleChange={(event: any) => {
              setMainState({ search: event.target.value });
            }}
          />
        </Grid>
        <Grid className={classes.button_container} xs={12} sm={12} md={6} lg={6} item>
          <CustomButtons
            frontIconChildren={<UploadFileIcon />}
            label={"添加公司"}
            handleClick={handleCreate}
            variant={"contained"}
          />
        </Grid>
      </Grid>

      <div className={classes.sub_title_container}>
        <div className={classes.sub_title_container_text}>
          <div className={classes.title_font}>全部公司</div>
        </div>
        <div className={classes.sub_title_container_line}>
          <Divider className={classes.divider} />
        </div>
      </div>
      <Divider />
      <CustomTable
        header={header}
        body={body}
        rowsPerPageOptions={[10, 25, 100]}
        count={mainState.total}
        rowsPerPage={mainState.limit}
        page={mainState.page}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
      />
      <DeletePopUp ref={deletePopUp} title="公司" handleSubmit={_handleDelete} />
      <CreateCompanyPopUp ref={companyPopUpRef} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
};

export default CompanyListPage;
