import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import clsx from "clsx";
import CustomButtons from "../component/CustomButtons";
//Material UI
import { makeStyles } from "@material-ui/core";
import { IconButton, Button } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import { useSnackbarProps } from "../../contexts/snackbar/SnackbarContext";

const useStyles = makeStyles((theme: any) => ({
  root_default: {
    padding: "6px",
  },
  root_header: {
    [theme.breakpoints.down("sm")]: {
      padding: "3px",
    },
  },
  mobile_size_logo: {
    [theme.breakpoints.down("sm")]: {
      width: "19px",
      height: "19px",
    },
  },
  share_button: {
    marginTop: 20,
    padding: "5px 40px",
    fontSize: 15,
    width: "100%",
    color: "white",
    [theme.breakpoints.down("md")]: {
      padding: "5px 22px",
    },
  },
}));

type CopyProps = {
  copyText: string;
};

type CopyButtonProps = {
  copyText: string;
  buttonText?: string;
  //   handleCopy?: () => void;
};

export const CustomCopyButton: React.FC<CopyButtonProps> = ({
  copyText,
  buttonText = "",
  //   handleCopy = ()=>{},
}) => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbarProps();
  
  return (
    <CopyToClipboard
      text={copyText}
      onCopy={() => {
        setSnackbar &&
          setSnackbar({
            snackbar: true,
            snackbarType: 0,
            snackbarContent: "複製成功！",
          });
      }}
    >
      <Button
        className={classes.share_button}
        variant="contained"
        color="primary"
        startIcon={<CopyIcon/>}

      >
        {buttonText}
      </Button>
    </CopyToClipboard>
  );
};

const CustomCopyToClipboard: React.FC<CopyProps> = ({ copyText }) => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbarProps();
  const [copied, setCopied] = useState(false);
  return (
    <CopyToClipboard
      text={copyText}
      //   text={`copy}\n${variables.websiteUrl}/register?referralCode=${referralCode}`}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
    >
      <div
        onClick={() => {
          setSnackbar &&
            setSnackbar({
              snackbar: true,
              snackbarType: 0,
              snackbarContent: "複製成功！",
            });
        }}
      >
        {copyText}
      </div>
    </CopyToClipboard>
  );
};

export default CustomCopyToClipboard;
