import ModelLoader from "./component/ModelLoader";
import React, {useEffect, useState} from "react";
import {GetBoothControllerProps} from "../controller/booth/type";
import {handleGetBoothAPI} from "../controller/booth";
import {useParams} from "react-router";
import {useSnackbarProps} from "../contexts/snackbar/SnackbarContext";
import {useBackdropProps} from "../contexts/backdrop";
import { GetProductControllerProps } from "../controller/product/type";
import { handleGetProductAPI } from "../controller/product";
import useQuery from "../hooks/useQuery";


const ModalWrapperPage = () => {
    // const link = 'https://api-panel.capritech.club/uploads/vEBx1Zp5/0412226b/scene_out/scene.gltf';
    const { boothID } = useParams<{ boothID: string }>();
    const [query, setQuery] = useQuery();
    const { setSnackbar } = useSnackbarProps();
    const { setBackdrop } = useBackdropProps();

    const [mainState, _setMainState] = useState({
        boothID: parseInt(boothID) || 0,
        objectData: {},
        files: {
            thumbnail: {
                ID: 0,
                name: "",
                path: "",
            },
            model: {
                ID: 0,
                name: "",
                path: "",
            },
        },
        refreshData: false,
    });


    const setMainState = (newState: any) => {
        _setMainState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    const _handleGetBoothAPI = () => {
        if (boothID) {
            const params: GetBoothControllerProps = {
                boothID: mainState.boothID,
                setMainState,
                changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
            };
            handleGetBoothAPI(params);
        }
    };

    const _handleGetProductAPI = () => {
          const params: GetProductControllerProps = {
            productID: mainState.boothID,
            setMainState,
            changeContext: { snackbarContext: setSnackbar, backdropContext: setBackdrop },
          };
          handleGetProductAPI(params);
      };

    useEffect(() => {
        if(query.isProduct == "1"){
            _handleGetProductAPI();
        }else{
            _handleGetBoothAPI();
        }
    }, [mainState.refreshData]);

    return (
        <React.Fragment>
            {mainState.files.model.path &&
                <ModelLoader link={mainState.files.model.path} defaultData={mainState.objectData} width={900} height={800} isProduct={query.isProduct == "1"} />
            }
        </React.Fragment>
    )
}

export default ModalWrapperPage
