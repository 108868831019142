import { useState } from "react";
import Router from "./Router";
import { IApp } from "./types/app";
import variables from "./config/variables.json";
import './assets/Model.css';
// contexts
import { theme, utilsContext, defaultStatus } from "./contexts";
import { SnackbarContextProvider } from "./contexts/snackbar/SnackbarContext";
import { BackdropContextProvider } from "./contexts/backdrop";
//Material UI
import { MuiThemeProvider } from "@material-ui/core/styles";
import { checkLocalStorage } from "./utils";

const App = () => {
  // add context provider at here
  const [utilsContextState, setUtilsContext] = useState(defaultStatus);

  const handleUtilsContextChange = (newState: IApp): void => {
    setUtilsContext((prevState: IApp) => ({
      ...prevState,
      ...newState,
    }));
  };

  window.localStorage.setItem(variables.local_storage_version, JSON.stringify(utilsContextState));

  //delete more than 1 local storage
  checkLocalStorage(variables.local_storage_version)

  return (
    <utilsContext.Provider
      value={{
        ...utilsContextState,
        _handleChange: handleUtilsContextChange,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <BackdropContextProvider>
          <SnackbarContextProvider>
            <Router />
          </SnackbarContextProvider>
        </BackdropContextProvider>
      </MuiThemeProvider>
    </utilsContext.Provider>
  );
};

export default App;
