import React, { forwardRef, useImperativeHandle, useState } from "react";
//Component
import CustomTextInput from "../CustomTextInput";
//material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
//img
import { Divider, FormControlLabel, Grid, Radio, Checkbox, Typography } from "@material-ui/core";
import clsx from "clsx";
import CustomButtons from "../CustomButtons";
import { isAccessPermission } from "../../../utils";

//Those Pages need >> Admin Page
//Use for create and edit admin

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    "& .MuiPaper-root": {
      backgroundColor: "white",
    },
  },
  dialog_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 50px 40px 50px",
    // minWidth: "50vw",
    backgroundColor: "white",
  },
  input_container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  input_des: {
    margin: "5px",
    fontSize: "18px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    "& .MuiInputBase-input": {
      color: theme.palette.common.black,
      fontSize: "15px",
    },
    "& .MuiTextField-root": {
      borderWidth: 0,
    },
  },

  input_title: {
    margin: "5px 0px",
    fontSize: "20px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    margin: "10px 0px 5px 0px",
  },
  sub_title_container: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "20px 0px 5px 0px",
  },
  sub_title: {
    // flex: 0.2,
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  submit_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "20px 50px",
  },
  switch_lb: {
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
  },
  complete_req_ctn: {
    marginTop: 20,
    marginBottom: 10,
  },
  numeric: {
    width: 60,
    height: 23,
    fontSize: 15,
  },
  radio_font: {
    fontSize: 13,
    color: "#000",
  },
  form_control_radio: {
    margin: "18px 16px 4px 0",
    "& .MuiRadio-root": {
      padding: 0,
      paddingRight: 9,
    },
  },
  form_control_icon: {
    "& svg": {
      width: 18,
      height: 18,
      fill: "#f8605f",
    },
  },
  form_control_checkbox: {},
  spacer: {
    marginTop: 19,
    textAlign: "left",
  },
  delete_button_container: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

type DeletePopUpProps = {
  title: string;
  handleSubmit: (ID: number) => void;
};

const DeletePopUp = React.forwardRef<any, DeletePopUpProps>(({ title, handleSubmit }, ref) => {
  const classes = useStyles();

  const [mainState, _setMainState] = useState({
    //TODO: add type
    ID: 0,
    name: "",
    reqRole: 0,
    userID: 0,
    role: 0,
    isOpen: false,
  });

  const allowDelete = isAccessPermission(mainState.role,mainState.reqRole,mainState.userID);

  const setMainState = (newState: any) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      isOpen: false,
      ID: 0,
      name: "",
    });
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  const _handleSubmit = () => {
    handleSubmit(mainState.ID);
  };

  return (
    <Dialog
      className={classes.dialog}
      open={mainState.isOpen}
      onClose={handleClose}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        <div className={classes.dialog_container}>
          <div className={classes.input_container}>
            <div className={classes.delete_button_container}>
              <p className={classes.input_title}>刪除{title}</p>
            </div>
            <div className={classes.sub_title_container}>
              {/* <div style={{ flex: 0.25 }}> */}
              <p className={classes.sub_title}>
                {allowDelete ? `確定刪除${mainState.name}?` : "目前沒有權限刪除"}
              </p>
              {/* </div> */}
              {/* <div style={{ flex: 0.8 }}>
                <Divider className={classes.divider} />
              </div> */}
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.submit_container}>
          <CustomButtons
            label="取消"
            variant="text"
            handleClick={handleClose}
            customStyle={{ fontSize: 14, paddingLeft: 40, paddingRight: 40 }}
          />
          <CustomButtons
            label="刪除"
            handleClick={_handleSubmit}
            disabled={!allowDelete}
            customStyle={{ fontSize: 14, paddingLeft: 40, paddingRight: 40, marginLeft: 10 }}
          />
        </div>
      </div>
    </Dialog>
  );
});

export default DeletePopUp;
