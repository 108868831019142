import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { IconButton, Typography } from "@material-ui/core";
import { OnClickEventHandler } from "../../types/common";
import Pagination from "@material-ui/lab/Pagination";
// import OpenIcon from "../../assets/sent/open.png";
// import CloseIcon from "../../assets/sent/close.png";
import OpenActiveIcon from "../../assets/sent/open_active.png";
import CloseActiveIcon from "../../assets/sent/close_active.png";

type StateProps = {
  page?: number;
  rowsPerPage?: number;
  order?: string;
  orderBy?: string;
};

type TableProps = {
  header: any[];
  body: any[];
  page?: number;
  rowsPerPageOptions?: number[];
  count?: number;
  rowsPerPage?: number;
  order: any;
  orderBy: string;
  orderByStateKey?: string;
  orderStateKey?: string;
  setState?: (p: StateProps) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.11)",
    [theme.breakpoints.between(1024, 1280)]: {
      width: "62vw",
      overflow: "scroll",
    },
    [theme.breakpoints.between(768, 1024)]: {
      width: "79vw",
      // overflow: "scroll",
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "79vw",
    //   overflow: "scroll",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  container: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflow: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
    // textAlign: "justify",
  },
  header_container: { 
    backgroundColor: theme.palette.primary.light,
  },
  header_top_container: {
    display: "flex",
    // backgroundColor: theme.palette.common.white,
    flex: 1,
    margin: "20px 25px",
  },
  result_font: {
    color: theme.palette.gray_2.main,
    fontSize: 14,
  },
  table_container: {
    overflow: "hidden",
    [theme.breakpoints.between(325, 1280)]: {
      minWidth: 1000,
    },
    // [theme.breakpoints.down("md")]: {
    //   minWidth: 1000,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   minWidth: 1000,
    //   overflow: "auto",
    // },
  },
  headerCol: {
    backgroundColor: "transparent",
    fontWeight: 500,
    fontSize: 15,
    color: theme.palette.primary.main,
    padding: 0,
    "&.MuiTableCell-root": {
      borderBottom: "none",
      minWidth: 50,
      [theme.breakpoints.between(325, 1280)]: {
        maxWidth: 150,
      },
    },
  },
  black_font_header: {
    paddingLeft: 15,
    color: "#97989b",
  },
  firstHeaderColumn: {
    padding: 10,
  },
  groupColumn: {
    maxWidth: "200px",
  },
  emptyRow: {
    fontSize: 15,
    border: 0,
  },
  bodyRow: {
    "&.MuiTableRow-hover:hover": {
      backgroundColor: theme.palette.gray.light,
    },
  },
  bodyCell: {
    borderTop: 1,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    borderStyle: "solid",
    color: theme.palette.gray_2.main,
    fontSize: 14,
    borderColor: theme.palette.gray_2.light,
    padding: "13px 5px",
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  news_cell: {
    maxWidth: 150,
  },
  firstBodyCell: {
    borderTop: 0,
  },
  indexRow: {
    width: 50,
  },
  firstBodyRow: {
    borderTop: 0,
  },
  visuallyHidden: {
    display: "none",
  },
  sortLabel: {
    "&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        // color: theme.palette.background.active
      },
    "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
      // color:"white",
      // color: theme.palette.background.active
    },
    "&:hover": {
      // color: theme.palette.gray.light
    },
    "&:focus": {
      // color: theme.palette.background.active
    },
  },
  pagination_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 40,
    // marginRight: 20,
  },
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "flex-end",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiPaginationItem-root": {
      backgroundColor: "#fff",
      fontSize: 14,
      color: theme.palette.gray.dark,
    },
  },
  noBoxShadow: {
    boxShadow: "none",
    width: "100%",
  },
  limit_size: {
    marginRight: -10,
    marginLeft: 10,
  },
  icon_container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
  },
  icon_active_container: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
  },
}));

const CustomTable: React.FC<TableProps> = (props) => {
  const {
    header,
    body,
    page = 1,
    rowsPerPageOptions = [10, 25, 100],
    count = 10,
    rowsPerPage = 10,
    order,
    orderBy,
    orderByStateKey = "",
    orderStateKey = "",
    setState = () => {},
  } = props;
  const classes = useStyles();
  const [isGroup, setGroup] = useState(true);
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    let key = "page";
    setState({ [key]: newPage });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleRequestSort = (event: OnClickEventHandler, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    let newOrder = isAsc ? "desc" : "asc";
    let orderKey = "order";
    let orderByKey = "orderBy";
    if (orderByStateKey) {
      orderByKey = orderByStateKey;
    }
    if (orderStateKey) {
      orderKey = orderStateKey;
    }

    setState({ [orderKey]: newOrder, [orderByKey]: property });
  };

  const createSortHandler = (property: string) => (event: OnClickEventHandler) => {
    handleRequestSort(event, property);
  };

  return (
    <React.Fragment>
      <Paper className={clsx(classes.root)}>
        <TableContainer className={clsx(classes.container)}>
          <Table className={clsx(classes.table_container)}>
            <TableHead className={clsx(classes.header_container)}>
              <TableRow>
                {header.map((column, index) => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={clsx(
                        column.label === "Group" && classes.groupColumn,
                        index === 0 && classes.firstHeaderColumn,
                        classes.headerCol
                      )}
                    >
                      {column.disabledSort ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          className={clsx(classes.sortLabel)}
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={column.disabledSort ? () => {} : createSortHandler(column.id)}
                          IconComponent={KeyboardArrowDownIcon}
                        >
                          {column.label}
                          {!column.disabledSort && orderBy === column.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!body.length && (
                <TableRow>
                  <TableCell className={classes.emptyRow} colSpan={header.length} align={"left"}>
                    <Typography style={{ fontSize: 15 }}>No Data Found</Typography>
                  </TableCell>
                </TableRow>
              )}
              {body.map((row, bodyIndex) => {
                return (
                  <TableRow className={classes.bodyRow} hover role="checkbox" tabIndex={-1} key={bodyIndex}>
                    {header.map((column, headerIndex) => {
                      const value = row[column.id];

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={clsx(
                            classes.bodyCell,
                            column.label === "Group" && classes.groupColumn,
                            bodyIndex === 0 && classes.limit_size,
                            headerIndex === 0 && classes.indexRow,
                            bodyIndex === body.length - 1
                          )}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <div className={classes.pagination_container}>
        <p className={classes.result_font}>
          Showing {1 + rowsPerPage * (page - 1)} to {rowsPerPage * page} of {count} results
        </p>

        <Pagination
          className={clsx(totalPages > 0 && classes.pagination)}
          page={page}
          count={totalPages}
          onChange={handleChangePage}
          defaultPage={1}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </div>
    </React.Fragment>
  );
};

export default CustomTable;
